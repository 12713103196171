import {Breadcrumb, FrontmaniaS3Object, S3ObjectType} from "../s3-browser.model";

export class BreadcrumbsBuilder {

  static buildBreadcrumbs(s3Object: FrontmaniaS3Object): Breadcrumb[] {
    if (!s3Object || s3Object.type === S3ObjectType.ROOT ) {
      return [];
    }
    const breadcrumbs: Breadcrumb[] = [];

    breadcrumbs.push({
      bucket: null,
      key: null,
      name: 'Buckets',
      type: S3ObjectType.ROOT
    });

    if (s3Object.bucket) {
      breadcrumbs.push({
        bucket: s3Object.bucket,
        key: null,
        name: s3Object.bucket,
        type: S3ObjectType.BUCKET
      });
    }

    if (s3Object.key) {
      const keySegments = s3Object.key.split('/').filter(segment => segment.length > 0);
      let subKey = '';
      for (let i = 0; i < keySegments.length - 1; i++) {
        subKey += keySegments[i] + '/';
        breadcrumbs.push({bucket: s3Object.bucket, key: subKey, name: keySegments[i], type: S3ObjectType.FOLDER});
      }
      breadcrumbs.push({bucket: s3Object.bucket, key: s3Object.key, name: s3Object.name, type: s3Object.type});
    }

    return breadcrumbs;
  }
}
