<fm-page>
  <fm-titlebar fmTitlebar>
    <div class="flex flex-col sm:flex-row gap-2 justify-between items-center">
      <div class="flex flex-col sm:flex-row gap-2 justify-start">
        <span>{{'s3browser.title' | translate}}</span>
      </div>
      <div class="flex flex-col sm:flex-row gap-2 justify-end">
        <button data-cy="s3-upload-button" mat-button *ngIf="isUploadButtonShown()" (click)="navigateToUpload()">
          <mat-icon>cloud_upload</mat-icon>
          {{'s3browser.upload' | translate}}
        </button>
        <button data-cy="s3-creat-folder-button" mat-button *ngIf="isCreateFolderButtonShown()" (click)="createFolder()">
          <mat-icon>create_new_folder</mat-icon>
          {{'s3browser.createFolder' | translate}}
        </button>
        <button data-cy="s3-delete-button" mat-button *ngIf="isDeleteButtonShown()" [disabled]="isDeleteButtonDisabled()"
                (click)="deleteSelectedObjects()">
          <mat-icon>delete</mat-icon>
          {{'s3browser.delete' | translate}}
        </button>
      </div>
    </div>

  </fm-titlebar>

  <div fmContent>
    <div class="fm-background-container">
    <frontmania-s3-breadcrumbs></frontmania-s3-breadcrumbs>
    <span *ngIf="isDocumentCountShown()">
      <frontmania-doc-count-info></frontmania-doc-count-info>
    </span>
    </div>

    <ng-template #loading>
      <mat-progress-spinner mode="indeterminate" diameter="14"></mat-progress-spinner>
    </ng-template>
    <ng-container *ngIf="isLoadingObjects">
      <div class="overlay">
        <mat-progress-spinner mode="indeterminate" diameter="60"></mat-progress-spinner>
      </div>
    </ng-container>

    <div class="table-container">
    <ng-container
      [ngTemplateOutlet]="s3Objects?.length > 0 ? table : noFiles">
    </ng-container>
    <ng-template #table>
      <table mat-table class="fm-row-hover" [dataSource]="s3Objects">

        <ng-container matColumnDef="select" style="width: 16px;">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox data-cy="s3-browser-check-all" (change)="$event ? masterToggle() : null"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="$event ? selection.toggle(row) : null"
                          [checked]="selection.isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="type" style="width: 16px;">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <mat-icon color="primary" *ngIf="row.s3Object.isBucket">menu</mat-icon>
            <mat-icon color="primary" *ngIf="row.s3Object.isFolder">folder</mat-icon>
            <mat-icon color="primary" *ngIf="row.s3Object.isFile">insert_drive_file</mat-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>{{'s3browser.columnHeader.name' | translate}}</th>
          <td mat-cell *matCellDef="let row">{{ row.s3Object.name }}</td>
        </ng-container>

        <ng-container matColumnDef="size">
          <th mat-header-cell *matHeaderCellDef>{{'s3browser.columnHeader.size' | translate}}</th>
          <td mat-cell
              *matCellDef="let row">{{ row.s3Object.size > 0 ? (row.s3Object.size | fileSize: selectedLocale() | default:'-') : '-' }}</td>
        </ng-container>

        <ng-container matColumnDef="modified">
          <th mat-header-cell *matHeaderCellDef>{{'s3browser.columnHeader.modified' | translate}}</th>
          <td mat-cell
              *matCellDef="let row">{{ row.s3Object.modified | date: 'medium':undefined: selectedLocale() | default:'-' }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr data-cy="s3-bucket-table-row" mat-row *matRowDef="let row; columns: displayedColumns;" (click)="browseTo(row.s3Object)">
        </tr>
      </table>

      <div *ngIf="isDocumentCountShown()" class="flex flex-row justify-center">
        <mat-paginator
           #paginator
           [length]="maxNumberOfElements"
           [pageSize]="pageSize"
           [pageSizeOptions]="pageSizeOptions"
           (page)="pageChangeEvent($event)">
        </mat-paginator>
      </div>
    </ng-template>

    <ng-template #noFiles>
      <div class="flex flex-row justify-center">
        {{'s3browser.noFiles' | translate}}
      </div>
    </ng-template>
    </div>
  </div>
</fm-page>
