import { Component, OnInit, ViewChild } from '@angular/core';
import * as AWS from 'aws-sdk';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Store} from '@ngxs/store';
import {SelectionModel} from '@angular/cdk/collections';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {I18nState} from '@frontmania/i18n';
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {S3ClientService} from "../../service/s3-client.service";
import {Bucket, ListObjectsOutput} from "aws-sdk/clients/s3";
import {DeleteConfirmDialogComponent} from "./delete-confirm-dialog/delete-confirm-dialog.component";
import {BrowserTableRow, FrontmaniaS3Object, FrontmaniaS3ObjectBuilder, S3ObjectType} from '../../s3-browser.model';
import {BrowseTo, LoadS3Credentials} from "../../s3-browser.actions";
import {CreateFolderDialogComponent} from "./create-folder-dialog/create-folder-dialog.component";
import {S3BrowserState} from "../../s3-browser-state";
import {map, pairwise} from "rxjs/operators";
import {filter, of, switchMap} from "rxjs";
import {NotificationType, OpenNotification} from "@frontmania/notification";
import {DataBaseClientService} from "../../service/database-client.service";
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';


@UntilDestroy()
@Component({
    selector: 'frontmania-s3-browser',
    templateUrl: './browser.component.html',
    styleUrls: ['./browser.component.scss'],
})
export class BrowserComponent implements OnInit {

  bucket: string;
  prefix: string;
  private previousPrefix = null;

  breadcrumbs: FrontmaniaS3Object[];

  displayedColumns: string[] = ['type', 'name', 'size', 'modified'];

  s3Objects: BrowserTableRow[];
  s3ObjectsDataSource: MatTableDataSource<BrowserTableRow>
  @ViewChild('paginator') paginator: MatPaginator;

  selection: SelectionModel<BrowserTableRow>;

  private dialogRef: MatDialogRef<CreateFolderDialogComponent>;

  isLoadingObjects = false;
  pageIndex = 0;
  pageIndexPrevious = null;
  pageSize = 100;
  pageSizeOptions: number[] = [100, 250, 500, 1000];
  maxNumberOfElements = 101;
  private currentMarker = "0";
  private isTruncated: boolean;
  private aboutToReset: boolean;

  private static extractName(key: string): string {
      const keyParts: string[] = key.split('/').filter((name) => name.length > 0);
      if (keyParts.length === 0) {
          return '/';
      }
      return keyParts[keyParts.length - 1];
  }

  constructor(
      private store: Store,
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private s3ClientService: S3ClientService,
      private databaseClientService: DataBaseClientService,
      public dialog: MatDialog,
  ) {
    this.s3ObjectsDataSource = new MatTableDataSource<BrowserTableRow>();
    // Watch over Router events on NavigationEnd which are triggered in other components
    if (this.router && this.router.events) {
      this.router.events.pipe(
        untilDestroyed(this)
      ).subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.updatePrefixFromRoute();
        }
      });
    }
  }

  ngOnInit() {
    this.breadcrumbs = [
      new FrontmaniaS3ObjectBuilder().name('Buckets').type(S3ObjectType.ROOT).build(),
    ];
    this.resetSelection();
    let bucket: string;
    let prefix: string;

    this.store.select(S3BrowserState.credentials).pipe(
      untilDestroyed(this),
      switchMap((credentials) => {
        if (!credentials) {
          return this.store.dispatch(new LoadS3Credentials())
        }
        return of(credentials)
      }),
      switchMap(() => {
        return this.activatedRoute.paramMap;
      })
    ).subscribe((params) => {
      bucket = params.get('bucket');
      prefix = params.get('prefix');

      this.setBreadcrumbs(bucket, prefix);
      this.store.dispatch(new BrowseTo(this.getCurrentBreadcrumbObject()));
      if (bucket) {
        this.isLoadingObjects = true;
        this.bucket = bucket;
        this.pageIndex = 0;
        this.currentMarker = "0";
        this.loadObjects(bucket, prefix);
      } else {
        this.loadBuckets();
      }
    });
    this.onRefreshButton();
    this.updatePrefixFromRoute()
  }

  ngAfterViewInit = (): void => {
    this.s3ObjectsDataSource.paginator = this.paginator;
  };

  private onRefreshButton() {
    if(this.router) {
      this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        pairwise(),
        untilDestroyed(this)
      )
        .subscribe(([previousEvent, currentEvent]: [NavigationEnd, NavigationEnd]) => {
          if (
            currentEvent.url !== previousEvent.url &&
            currentEvent.urlAfterRedirects !== previousEvent.urlAfterRedirects
          ) {
            this.onBackOrForwardButtonClick();
          }
        });
    }
  }

  private onBackOrForwardButtonClick() {
    const currentParams = this.activatedRoute.snapshot.paramMap;
    const bucket = currentParams.get('bucket');
    const prefix = currentParams.get('prefix') || "";

    if (bucket) {
      this.databaseClientService.triggerCountAction(bucket, prefix);
    }
  }

  navigateToUpload(s3Object?: FrontmaniaS3Object): void {
      let targetS3Object = s3Object;
      if (!targetS3Object) {
          targetS3Object = this.getCurrentBreadcrumbObject();
      }
      const params = {};
      if (targetS3Object.bucket) {
          params['bucket'] = targetS3Object.bucket;
      }
      if (targetS3Object.key) {
          params['prefix'] = targetS3Object.key;
      }
      this.router.navigate(['s3/upload', params]);
  }

  browseTo(s3Object: FrontmaniaS3Object): void {
    const params = {};
    if (s3Object.bucket) {
      params['bucket'] = s3Object.bucket;
    }
    if (s3Object.key) {
      params['prefix'] = s3Object.key;
    }
    if (s3Object.isFile) {
      this.store.dispatch(new BrowseTo(s3Object));
      this.router.navigate(['s3/info', params]);
    } else {
      this.router.navigate(['s3/browser', params]);
    }
    this.databaseClientService.triggerCountAction(s3Object.bucket, s3Object.key);
  }

  private updatePrefixFromRoute() {
    this.activatedRoute.paramMap.pipe(
      untilDestroyed(this)
    ).subscribe((params) => {
      this.prefix = params.get('prefix') || '';
      this.bucket = params.get('bucket') || '';
    });
  }


  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
      const numSelected = this.selection.selected.length;
      const numRows = this.s3Objects.length;
      return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
      this.isAllSelected()
          ? this.selection.clear()
          : this.s3Objects.forEach((row) => this.selection.select(row));
  }

  deleteSelectedObjects() {
      const objectIdentifiers: AWS.S3.Types.ObjectIdentifier[] = this.selection.selected.map(
          (row) => {
              const objectIdentifier: AWS.S3.Types.ObjectIdentifier = {
                  Key: row.s3Object.key,
              };
              return objectIdentifier;
          }
      );

      const dialogRef = this.dialog.open(DeleteConfirmDialogComponent, {
          data: {
              width: '350px',
              objects: objectIdentifiers,
              confirmDelete: false
          }
      });

      dialogRef.afterClosed().pipe(
          untilDestroyed(this),
          filter((confirmDelete) => !!confirmDelete),
          switchMap(() => {
              const deleteObjectsRequest: AWS.S3.Types.DeleteObjectsRequest = {
                  Bucket: this.bucket,
                  Delete: {
                      Objects: objectIdentifiers,
                  },
              };
              return this.s3ClientService.deleteObjects(deleteObjectsRequest);
          }),
      ).subscribe(() => {
          this.store.dispatch(new OpenNotification(NotificationType.SUCCESS, 's3.browser.delete.success'));
          const s3Object = this.breadcrumbs[this.breadcrumbs.length - 1];
          this.resetSelection();
          this.loadObjects(s3Object.bucket, s3Object.key);
      });
  }

  isDeleteButtonDisabled(): boolean {
      return this.selection.selected.length === 0;
  }

  isUploadButtonShown(): boolean {
      const s3Object = this.getCurrentBreadcrumbObject();
      return s3Object.isBucket || s3Object.isFolder;
  }

  isDeleteButtonShown(): boolean {
      const s3Object = this.getCurrentBreadcrumbObject();
      return !s3Object.isRoot;
  }

  isCreateFolderButtonShown(): boolean {
      const s3Object = this.getCurrentBreadcrumbObject();
      return s3Object.isBucket || s3Object.isFolder;
  }

  isDocumentCountShown(): boolean {
    const s3Object = this.getCurrentBreadcrumbObject();
    return s3Object.isBucket || s3Object.isFolder;
  }

  selectedLocale(): string {
      return this.store.selectSnapshot(I18nState.selectedLocale)
  }

  createFolder(): void {
      this.dialogRef = this.dialog.open(CreateFolderDialogComponent, {
          width: '450px',
          data: {
              s3Object: this.getCurrentBreadcrumbObject(),
              newFolderName: '',
          },
      });

      this.dialogRef.afterClosed().pipe(
          untilDestroyed(this),
          filter((result) => !!result),
          switchMap((result) => {
              const folderForS3Object: FrontmaniaS3Object = result.s3Object;
              const key = folderForS3Object.key ? folderForS3Object.key : '';
              const request: AWS.S3.Types.PutObjectRequest = {
                  Bucket: this.bucket,
                  Key: key + result.newFolderName + '/',
              };
              return this.s3ClientService.createFolder(request).pipe(
                  map(() => [folderForS3Object.bucket, folderForS3Object.key])
              );
          })
      ).subscribe(() => {
        this.store.dispatch(new OpenNotification(NotificationType.SUCCESS, 's3.browser.createfolder.success'));

        this.loadObjects(this.bucket, this.prefix);
      });
  }

  private getCurrentBreadcrumbObject(): FrontmaniaS3Object {
      return this.breadcrumbs[this.breadcrumbs.length - 1];
  }

  private resetSelection() {
      const initialSelection = [];
      const allowMultiSelect = true;
      this.selection = new SelectionModel<BrowserTableRow>(
          allowMultiSelect,
          initialSelection
      );
  }

  private setBreadcrumbs(bucket: string, prefix: string) {
      this.breadcrumbs = [
          new FrontmaniaS3ObjectBuilder().name('Buckets').type(S3ObjectType.ROOT).build()
      ];
      if (bucket) {
          this.breadcrumbs.push(
              new FrontmaniaS3ObjectBuilder().bucket(bucket).name(bucket).type(S3ObjectType.BUCKET).build()
          );
      }
      if (prefix) {
          const prefixParts: string[] = this.collectPrefixParts(prefix);
          prefixParts.forEach((value, index) => {
              const subPrefix = this.buildSubPrefix(index, prefixParts, prefix);
              this.buildBreadcrumbs(bucket, subPrefix, value);
          });
      }
  }

  private buildBreadcrumbs(bucket: string, subPrefix: string, value: string) {
      this.breadcrumbs.push(
          new FrontmaniaS3ObjectBuilder().bucket(bucket)
              .name(value)
              .key(subPrefix)
              .type(S3ObjectType.FOLDER)
              .build()
      );
  }

  private buildSubPrefix(index: number, prefixParts: string[], prefix: string) {
      let subPrefix = '';
      for (let i = 0; i < index + 1; i++) {
          if (i > 0) {
              subPrefix += '/';
          }
          subPrefix += prefixParts[i];
      }
      if (prefix.endsWith('/')) {
          subPrefix += '/';
      }
      return subPrefix;
  }

  private collectPrefixParts(prefix: string) {
      return prefix
          .split('/')
          .map((part) => part.trim())
          .filter((part) => part.length > 0);
  }

  private loadBuckets(): void {
      this.s3ClientService.listBuckets().pipe(
          untilDestroyed(this)
      ).subscribe((buckets) => {
          this.processBuckets(buckets);
      });

  }

  private processBuckets(buckets: Bucket[]) {
      this.s3Objects = buckets.map(
          (bucket) =>
              new BrowserTableRow(
                  new FrontmaniaS3ObjectBuilder()
                      .bucket(bucket.Name)
                      .name(bucket.Name)
                      .key('')
                      .type(S3ObjectType.BUCKET)
                      .build()
              )
      );
      this.toggleSelectBoxColumn(true);
  }


  private loadObjects(bucket: string, prefix: string): void {
    this.bucket = bucket;
    this.prefix = prefix;

    if (!prefix) {
      prefix = '';
    }

    this.s3ClientService.listObjects(bucket, prefix, this.currentMarker, this.pageSize).pipe(
      untilDestroyed(this)
    ).subscribe({
      next: (data) => {
        const s3Objects: BrowserTableRow[] = [];

        if (data.CommonPrefixes) {
          this.processCommonPrefixes(data, s3Objects, bucket);
        }
        if (data.Contents) {
          this.processContent(data, s3Objects, bucket);
        }
        this.s3Objects = s3Objects;
        this.toggleSelectBoxColumn(false);
        this.isTruncated = data.IsTruncated;

        if (this.previousPrefix != this.prefix) {
          this.pageIndex = 0;
          //this.paginator.firstPage();
          this.maxNumberOfElements = this.pageSize + 1;
        }
        const newMaxElements = (this.pageSize * this.pageIndex) + this.fetchElements(data) + 1;

        if (data.IsTruncated) {
          if (this.pageIndex > this.pageIndexPrevious) {
            if (this.maxNumberOfElements < newMaxElements) {
              this.maxNumberOfElements = newMaxElements;
            }
          } else if (this.pageIndex === this.pageIndexPrevious && this.pageIndexPrevious > 0) {
            // no changes on maxNumberOfElements
          } else if (this.pageIndexPrevious != null){
            this.maxNumberOfElements = this.pageSize + 1;
          }
        } else  {
          if (this.pageIndex > this.pageIndexPrevious) {
            this.maxNumberOfElements = (this.pageSize * this.pageIndex) + this.fetchElements(data);
          } else if (this.pageIndex === 0) {
            if (this.fetchElements(data) < this.pageSize) {
              this.maxNumberOfElements = this.fetchElements(data);
            }
          }
        }
        this.previousPrefix = this.prefix;
      },
      complete: () => {
        this.isLoadingObjects = false;
      },
      error: (err) => {
        console.error("Error loading objects:", err);
        this.isLoadingObjects = false;
      }
    });
  }



  fetchElements(data: AWS.S3.ListObjectsOutput): number {
    const topLevelObjects: object[] = data.Contents ? data.Contents.filter(obj => !obj.Key.includes('/', this.prefix.length)) : [];
    const totalElementsCount = topLevelObjects.length;
    const totalPrefixCount = data.CommonPrefixes ? data.CommonPrefixes.length : 0;

    return totalPrefixCount + totalElementsCount;
  }

  private processContent(data: ListObjectsOutput, s3Objects: BrowserTableRow[], bucket: string) {
      if (Array.isArray(data.Contents)) {
          data.Contents.forEach((content) => {
              s3Objects.push(
                  new BrowserTableRow(
                      new FrontmaniaS3ObjectBuilder()
                          .bucket(bucket)
                          .name(BrowserComponent.extractName(content.Key))
                          .key(content.Key)
                          .type(S3ObjectType.FILE)
                          .size(content.Size)
                          .modified(content.LastModified)
                          .build()
                  )
              );
        });
      } else {
         console.error('Error: data.Contents is not an array', data.Contents);
      }
  }

  private processCommonPrefixes(data: ListObjectsOutput, s3Objects: BrowserTableRow[], bucket: string) {
      data.CommonPrefixes.forEach((commonPrefix) =>
          s3Objects.push(
              new BrowserTableRow(
                  new FrontmaniaS3ObjectBuilder()
                      .bucket(bucket)
                      .name(BrowserComponent.extractName(commonPrefix.Prefix))
                      .key(commonPrefix.Prefix)
                      .type(S3ObjectType.FOLDER)
                      .build()
              )
          )
      );
  }

  private toggleSelectBoxColumn(isRoot: boolean) {
    const index = this.displayedColumns.indexOf('select');
    if (isRoot) {
      if (index > -1) {
        this.displayedColumns.splice(index, 1);
      }
      if (index < 0) {
        this.displayedColumns.unshift('select');
      } else {
            console.error("Error: Unknown handling of toggleSelectBoxColumn")
          }
      }
  }

  pageChangeEvent(event: PageEvent): void {
    if(this.aboutToReset) {
      this.aboutToReset = false;
      return
    }
    this.isLoadingObjects = true;

      if (event.pageSize > this.pageSize) {
        this.pageIndex = 0;
        event.pageIndex = 0;
        if(this.isTruncated) {
          this.currentMarker = "0";
          this.pageSize = event.pageSize;
        } else {
          // Marker no change
          // MaxElements changed by loadObjects
          this.pageSize = event.pageSize;
        }
      } else if (event.pageSize < this.pageSize) {
        this.pageIndex = 0;
        event.pageIndex = 0;
        if(this.isTruncated) {
          this.currentMarker = "0";
          this.maxNumberOfElements = event.pageSize + 1;
          this.pageSize = event.pageSize;
        } else {
          if(this.maxNumberOfElements > event.pageSize + 1) {
            this.currentMarker = "0";
          }
          // Marker no change
          // MaxElements changed by loadObjects
          this.pageSize = event.pageSize;
        }
      }
    if(this.pageIndex === 0 && event.previousPageIndex != 0) {
      this.pageIndexPrevious = this.pageIndex;
      this.aboutToReset = true;
      this.loadObjects(this.bucket, this.prefix);
      this.paginator.firstPage();
      return;
    }

    this.pageIndexPrevious = this.pageIndex;

    if (event.pageSize === this.pageSize) {
      // Determine maxNumberOfElements and currentMarker when using paginator controls
      if (event.pageIndex > event.previousPageIndex) {
        // Regular page forward
        if (this.isTruncated) {
          this.currentMarker = String(event.pageSize * event.pageIndex);
          this.pageIndex = event.pageIndex;
        }
      }
      // Regular page backward
      if (event.pageIndex < event.previousPageIndex) {
        if ((Number(this.currentMarker) - event.pageSize) < 0) {
          this.currentMarker = "0";
        } else {
          this.currentMarker = String(Number(this.currentMarker) - event.pageSize);
        }
      }
    }
    this.loadObjects(this.bucket, this.prefix);
  }

}
