import {Injectable} from '@angular/core';
import * as AWS from 'aws-sdk';
import {Credentials} from 'aws-sdk';
import {Observable} from 'rxjs';
import {S3BrowserConfig} from '../s3-browser.config';
import {
  DeleteObjectsRequest,
  GetObjectRequest,
  PutObjectRequest
} from "aws-sdk/clients/s3";
import {Store} from "@ngxs/store";
import {S3BrowserState} from "../s3-browser-state";
import {PutObjectResponse} from "aws-sdk/clients/mediastoredata";

export interface S3UploadResponse {
  key: string;
  error?: unknown;
}

@Injectable({
  providedIn: 'root',
})

export class S3ClientService {

  s3Client: AWS.S3;

  constructor(private s3Config: S3BrowserConfig,
              private store: Store) {
  }

  getS3Client(): AWS.S3 {
    if (this.s3Client) {
      return this.s3Client;
    }
    return this.initS3Client();
  }

  private initS3Client(): AWS.S3 {
    const currentCredentials = this.store.selectSnapshot(S3BrowserState.credentials);

    const s3Client: AWS.S3 = new AWS.S3({
      endpoint: this.s3Config.s3EndpointUrl,
      credentials: new Credentials(
        currentCredentials.accessKeyId,
        currentCredentials.secretAccessKey
      ),
      signatureVersion: 'v4',
    });
    this.s3Client = s3Client;
    return s3Client;
  }

  listBuckets(): Observable<AWS.S3.Buckets> {
    return new Observable<AWS.S3.Buckets>(subscriber => {
      this.getS3Client().listBuckets((err, data) => {
        if (err) {
          subscriber.error(err);
        } else {
          subscriber.next(data.Buckets);
        }
        subscriber.complete();
      })
    });
  }

  listObjects(bucket: string, prefix: string, newMarker: string, pageSize: number): Observable<AWS.S3.ListObjectsOutput> {
    return new Observable<AWS.S3.ListObjectsOutput>(subscriber => {
      this.getS3Client().listObjects({
        Bucket: bucket,
        Prefix: prefix,
        Delimiter: '/',
        MaxKeys: pageSize,
        Marker: newMarker
      }, (err, data) => {
        if (err) {
          subscriber.error(err);
        } else {
          subscriber.next(data);
        }
        subscriber.complete();
      });
    });
  }



  deleteObjects(deleteObjectsRequest: DeleteObjectsRequest) {
    return new Observable<AWS.S3.DeleteObjectOutput>(subscriber => {
      this.getS3Client().deleteObjects(deleteObjectsRequest, (err, data) => {
        if (err) {
          subscriber.error(err)
        } else {
          subscriber.next(data);
        }
        subscriber.complete();
      });
    });
  }

  createFolder(request: PutObjectRequest) {
    return new Observable<PutObjectResponse>(subscriber => {
      this.getS3Client().putObject(request, (err, data) => {
        if (err) {
          subscriber.error(err);
        } else {
          subscriber.next(data);
        }
        subscriber.complete();
      });
    });
  }

  putObject(request: PutObjectRequest) {
    return new Observable<S3UploadResponse>(subscriber => {
      this.getS3Client().putObject(request, (err) => {
        if (err) {
          subscriber.next({
            key: request.Key,
            error: err
          });
        } else {
          subscriber.next({
            key: request.Key
          });
        }
        subscriber.complete();
      });
    });
  }

  getObject(request: GetObjectRequest) {
    return new Observable<AWS.S3.GetObjectOutput>(subscriber => {
      this.getS3Client().getObject(request, (err, data) => {
        if (err) {
          subscriber.error(err);
        } else {
          subscriber.next(data);
        }
        subscriber.complete();
      });
    });
  }

  getSignedUrl(object: string, params: { Bucket: string; Key: string }) {
    return new Observable<string>(subscriber => {
      this.getS3Client().getSignedUrl(object, params, (err, data) => {
          if (err) {
            subscriber.error(err)
          } else {
            subscriber.next(data);
          }
          subscriber.complete();
        }
      )
      ;
    });
  }

}
