import { Component, OnInit } from '@angular/core';
import { combineLatest, distinctUntilChanged, Observable } from 'rxjs';
import { I18nState, SelectLocale } from '@frontmania/i18n';
import { AuthState, CurrentUser, Logout } from '@frontmania/auth';
import { filter } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { ObjectMasterDataService } from '@frontmania/object-master-data';
import { ObjectCheckInConfigService } from '@frontmania/object-check-in';
import { ObjectSearchService } from '@frontmania/object-search';
import { MaintenanceInfoService } from '@frontmania/maintenance-info';
import { environment } from '../environments/environment';

@UntilDestroy()
@Component({
  selector: 'frontmania-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  @Select(AuthState.currentUser) currentUser$: Observable<CurrentUser>
  @Select(I18nState.selectedLocale) selectedLocale$: Observable<string>
  @Select(I18nState.availableLocales) availableLocales$: Observable<string[]>

  exportAdminViewEnabled = environment.exportAdminViewEnabled ?? false;

  constructor(private store: Store,
              private objectMasterDataService: ObjectMasterDataService,
              private objectCheckInConfigService: ObjectCheckInConfigService,
              private objectSearchService: ObjectSearchService,
              private maintenanceService: MaintenanceInfoService) {
  }

  ngOnInit(): void {
    combineLatest([this.store.select(I18nState.selectedLocale), this.store.select(AuthState.authorized)]).pipe(
      filter(([locale, authorized]) => !!locale && !!authorized),
      untilDestroyed(this)
    ).subscribe(([locale]) => this.objectMasterDataService.updateLanguage(locale));

    this.store.select(AuthState.currentUser).pipe(
      distinctUntilChanged(),
      filter(user => !!user)
    ).subscribe(user => {
      const apply = user.roles?.some(role => role.endsWith('-admin')) || user.roles?.some(role => role.startsWith('ecm-kfl-log-ki-'));
      this.objectCheckInConfigService.applyFillStrategies$$.next(!apply);
      this.objectSearchService.applyFillStrategies$$.next(!apply);
    });
  }


  get logoutButtonVisible() {
    return environment?.oauth2?.logoutEnabled ?? true;
  }

  get maintenanceRunning() {
    return this.maintenanceService.isMaintenanceRunning();
  }

  logout() {
    this.store.dispatch(new Logout())
  }

  selectLocale(locale: string) {
    this.store.dispatch(new SelectLocale(locale))
  }
}
