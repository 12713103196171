import {ModuleWithProviders, NgModule} from '@angular/core';
import {UploadComponent} from './components/upload/upload.component';
import {InfoComponent} from './components/info/info.component';
import {RouterModule} from '@angular/router';
import {MaterialModule} from '@frontmania/material';
import {NgxsModule} from '@ngxs/store';
import {BreadcrumbsComponent} from './components/breadcrumbs/breadcrumbs.component';
import {FormsModule} from '@angular/forms';
import {S3BrowserConfig} from './s3-browser.config';
import {ClipboardModule} from "@angular/cdk/clipboard";
import {I18nModule} from "@frontmania/i18n";
import {CommonModule} from '@angular/common';
import {authGuard, SecurityTemplates} from '@frontmania/auth';
import {UnitsModule} from '@frontmania/units';
import {DefaultPipe} from "./util/default.pipe";
import {DeleteConfirmDialogComponent} from "./components/browser/delete-confirm-dialog/delete-confirm-dialog.component";
import {S3BrowserState} from "./s3-browser-state";
import {BrowserComponent} from "./components/browser/browser.component";
import {CreateFolderDialogComponent} from "./components/browser/create-folder-dialog/create-folder-dialog.component";
import {NgxFileDropModule} from "ngx-file-drop";
import {DocCountInfoComponent} from "./components/browser/doc-count-info/doc-count-info.component";
import {ObjectSearchConfig} from '@frontmania/object-search';

const routes = [
  {
    path: 's3/browser',
    component: BrowserComponent,
    canActivate: [authGuard],
    data: {securityTemplate: SecurityTemplates.S3BROWSER_ACCESS}
  },
  {
    path: 's3/info',
    component: InfoComponent,
    canActivate: [authGuard],
    data: {securityTemplate: SecurityTemplates.S3BROWSER_ACCESS}
  },
  {
    path: 's3/upload',
    component: UploadComponent,
    canActivate: [authGuard],
    data: {securityTemplate: SecurityTemplates.S3BROWSER_ACCESS}
  },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    NgxsModule.forFeature([S3BrowserState]),
    RouterModule.forChild(routes),
    ClipboardModule,
    I18nModule,
    UnitsModule,
    NgxFileDropModule
  ],
  declarations: [
    DeleteConfirmDialogComponent,
    BrowserComponent,
    InfoComponent,
    UploadComponent,
    DefaultPipe,
    BreadcrumbsComponent,
    CreateFolderDialogComponent,
    DocCountInfoComponent
  ],
})
export class S3BrowserModule {

  static forRoot(config?: S3BrowserConfig): ModuleWithProviders<S3BrowserModule> {
    return {
      ngModule: S3BrowserModule,
      providers: [{
        provide: S3BrowserConfig,
        useValue: config
      }]
    };
  }
}
export class ObjectSearchModule {

  static forRoot(config?: ObjectSearchConfig): ModuleWithProviders<ObjectSearchModule> {
    return {
      ngModule: ObjectSearchModule,
      providers: [{
        provide: ObjectSearchConfig,
        useValue: config
      }]
    };
  }
}
