<fm-page>
  <fm-titlebar fmTitlebar>
    <div class="button-area flex flex-col sm:flex-row gap-2 justify-between items-center">
      <div class="flex flex-col sm:flex-row gap-2 justify-start">
        <span>{{'s3browser.title' | translate}}</span>
      </div>
      <div class="flex flex-col sm:flex-row gap-2 justify-end">
        <button mat-button (click)="open()">
          <mat-icon>open_in_browser</mat-icon>
          {{'s3browser.open' | translate}}
        </button>
        <button mat-button [cdkCopyToClipboard]="objectUrl()">
          <mat-icon>content_copy</mat-icon>
          {{'s3browser.copyPath' | translate}}
        </button>
      </div>
    </div>
  </fm-titlebar>
  <div fmContent>
    <frontmania-s3-breadcrumbs></frontmania-s3-breadcrumbs>
    <div class="fm-background-container">
      <ng-container [ngTemplateOutlet]="!!s3InfoObject ? content : noInfo">
        <ng-template #content>
          <p>
            <strong>{{'s3browser.info.lastModified' | translate}}</strong><br/>
            {{ s3InfoObject.lastModified | date:'medium':undefined:selectedLanguage() }}
          </p>
          <p><strong>{{'s3browser.info.contentType' | translate}}</strong><br/>
            {{ s3InfoObject.contentType }}</p>
          <p><strong>{{'s3browser.info.size' | translate}}</strong><br/>
            {{ s3InfoObject.contentSize | fileSize:selectedLanguage() }}</p>
          <p><strong>{{'s3browser.info.key' | translate}}</strong><br/>
            {{ key }}</p>
          <p><strong>{{'s3browser.info.objectUrl' | translate}}</strong><br/>
            <a target="_blank" rel="noopener noreferrer"
               href="{{ s3InfoObject.objectUrl }}">{{ s3InfoObject.objectUrl }}</a>
          </p>
        </ng-template>
        <ng-template #noInfo><p>{{'s3Browser.info.noInfo'| translate }} </p></ng-template>
      </ng-container>

    </div>

  </div>
</fm-page>
