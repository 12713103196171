import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import {combineLatest, Observable} from 'rxjs';
import { Store } from '@ngxs/store';
import { I18nState } from '@frontmania/i18n';
import { map } from 'rxjs/operators';
import { DecimalPipe } from '@angular/common';
import {S3BrowserState} from "../../../s3-browser-state";

@UntilDestroy()
@Component({
  selector: 'frontmania-doc-count-info',
  templateUrl: './doc-count-info.component.html',
  styleUrls: ['./doc-count-info.component.scss']
})
export class DocCountInfoComponent {

  constructor(private store: Store, private decimalPipe: DecimalPipe) {}

  totalNumberOfDocumentsByQuery$: Observable<string> = this.transformNumber(
    this.store.select(S3BrowserState.totalNumberOfDocumentsByQuery),
    500000,
    '>'
  );

  private transformNumber(selector: Observable<number>, defaultCount: number, prefix = ''): Observable<string> {
    return combineLatest([
      selector,
      this.store.select(I18nState.selectedLocale)
    ]).pipe(
      map(([count, local]) => {
        if (count === undefined) {
          return undefined;
        }
        return count === -1 ? `${prefix} ${this.decimalPipe.transform(defaultCount, undefined, local)}` : this.decimalPipe.transform(count, null, local);
      })
    );
  }
}

