<fm-page>
  <fm-titlebar fmTitlebar>
    <div class="flex flex-row justify-between items-center">
      <span>{{'choiceValues.editing.title' | translate}} {{choiceValueName.toUpperCase()}}</span>
      <button data-cy="add-cv-button" *ngIf="canCreateValue" mat-button (click)="addNewValue()">
        <mat-icon>add</mat-icon>
        {{'choiceValues.editing.addNew' | translate}}
      </button>
    </div>
  </fm-titlebar>

  <div fmContent class="layout-container flex flex-row justify-start">

    <table class="fm-row-hover" mat-table [dataSource]="cvDataSource" ngDefaultControl>
      <ng-container [matColumnDef]="column" *ngFor="let column of columns">
        <th mat-header-cell *matHeaderCellDef> {{'choiceValues.editing.header.' + column | translate}} </th>
        <ng-template [ngIf]="column === 'value'" [ngIfElse]="other">
          <td mat-cell *matCellDef="let element">
            <div>
              <div>{{element.value}}</div>
            </div>
          </td>
        </ng-template>

        <ng-template #other>
          <td mat-cell *matCellDef="let element">
            <ng-template [ngIf]="element?.labels" [ngIfElse]="missingValue">
              <input [attr.data-cy]="'label-input-' + column"
                     class="fm-small-input"
                     [disabled]="!canUpdateLabel"
                     matInput [value]="element.labels[column.toLowerCase()]"
                     #labelEditing
                     (change)="updateLabel(element.value, column.toLowerCase(), labelEditing.value)"/>
            </ng-template>
            <ng-template #missingValue>
              <span>{{'choiceValues.editing.missingValue' | translate}}</span>
            </ng-template>
          </td>
        </ng-template>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
  </div>
</fm-page>
