import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {append, patch, updateItem} from '@ngxs/store/operators';
import {combineLatest, from, mergeMap, of, tap} from "rxjs";
import {S3ClientService} from './service/s3-client.service';
import {DataBaseClientService} from "./service/database-client.service";
import {
  AddUploadFile,
  BrowseTo,
  ClearUploadList,
  CountObjectsByQuery,
  LoadS3Credentials,
  SetUploadTarget,
  StartUpload,
  StopUpload
} from './s3-browser.actions';
import {UploadsHelper} from "./util/uploads.helper";
import {FrontmaniaS3Object, S3BrowserStateModel, STATE_DEFAULTS, Upload, UploadStateType} from "./s3-browser.model";
import {S3CredentialsLoader} from "./service/s3-credentials.loader";
import {BreadcrumbsBuilder} from "./util/breadcrumbs.builder";

@State<S3BrowserStateModel>({
  name: 's3Browser',
  defaults: STATE_DEFAULTS
})


@Injectable()
export class S3BrowserState {

  @Selector()
  static credentials(state: S3BrowserStateModel) {
    return state.credentials;
  }

  @Selector()
  static isUploading(state: S3BrowserStateModel) {
    return state.isUploading;
  }

  @Selector()
  static target(state: S3BrowserStateModel) {
    return state.target;
  }

  @Selector()
  static breadcrumbs(state: S3BrowserStateModel) {
    return state.breadCrumbs;
  }

  @Selector()
  static uploads(state: S3BrowserStateModel) {
    const uploads: Upload[] = Object.keys(state.uploads).map(function (index) {
      return state.uploads[index];
    });
    return uploads;
  }

  @Selector()
  static loadedDocuments(state: S3BrowserStateModel) {
    return state.loadedDocuments;
  }

  @Selector()
  static totalNumberOfDocumentsByBucket(state: S3BrowserStateModel) {
    return state.totalElementsByBucket;
  }

  @Selector()
  static totalNumberOfDocumentsByQuery(state: S3BrowserStateModel) {
    return state.totalElementsByQuery;
  }

  @Selector()
  static loadedNumberOfDocuments(state: S3BrowserStateModel) {
    return state.loadedDocuments?.length ?? 0;
  }

  constructor(private s3ClientService: S3ClientService,
              private credentialsLoader: S3CredentialsLoader,
              private databaseClientService: DataBaseClientService) {
  }

  @Action(LoadS3Credentials)
  loadS3Credentials(ctx: StateContext<S3BrowserStateModel>) {
    return this.credentialsLoader.getCredentials().pipe(
      tap((credentials) => ctx.patchState({credentials: credentials}))
    )
  }

  @Action(SetUploadTarget)
  setTarget(ctx: StateContext<S3BrowserStateModel>, action: SetUploadTarget) {
    ctx.patchState({target: action.target});
  }

  @Action(AddUploadFile)
  addFile(ctx: StateContext<S3BrowserStateModel>, action: AddUploadFile) {
    const file = action.fileEntry.file;
    const upload: Upload = {
      state: UploadStateType.PENDING,
      path: file.name,
      file: file,
      size: file.size,
      type: file.type,
    }
      ctx.setState(
        patch({
          uploads: append([upload]),
        })
      );
  }


  @Action(StopUpload)
  stopUpload(ctx: StateContext<S3BrowserStateModel>) {
    ctx.patchState({isUploading: false})

  }

  @Action(ClearUploadList)
  clearList(ctx: StateContext<S3BrowserStateModel>, action: ClearUploadList) {
    if (!action.keepFailed) {
      ctx.patchState({uploads: []});
    }
    ctx.patchState({uploads: [...ctx.getState().uploads.filter(upload => upload.state === UploadStateType.ERROR)]});

  }

  @Action(StartUpload)
  startUpload(ctx: StateContext<S3BrowserStateModel>) {

    ctx.patchState({isUploading: true});
    const target: FrontmaniaS3Object = ctx.getState().target;
    const uploads: Upload[] = ctx.getState().uploads;

    return from(uploads.filter(upload => upload.state !== UploadStateType.SUCCESS)).pipe(
      tap((upload) => {
        this.setUploadState(ctx, upload, UploadStateType.RUNNING);
        return upload;
      }),
      mergeMap((upload) => {
        const request = UploadsHelper.buildRequest(target.key, upload, target.bucket);
        return combineLatest([
          of(upload),
          this.s3ClientService.putObject(request)
        ])
      }),
      tap(([upload, resp]) => {
        this.setUploadState(ctx, upload, resp.error ? UploadStateType.ERROR : UploadStateType.SUCCESS);
      }),
    )
  }

  private setUploadState(ctx: StateContext<S3BrowserStateModel>, upload: Upload, state: UploadStateType) {
    return ctx.setState(
      patch({
        uploads: updateItem<Upload>(
          (item) => item.path === upload.path,
          patch<Upload>({state: state.valueOf()})
        ),
      })
    );
  }

  @Action(BrowseTo)
  browseTo(ctx: StateContext<S3BrowserStateModel>, action: BrowseTo) {
    ctx.patchState({breadCrumbs: BreadcrumbsBuilder.buildBreadcrumbs(action.s3Object)});
  }

  @Action(CountObjectsByQuery, {cancelUncompleted: true})
  countObjectsByQuery(ctx: StateContext<S3BrowserStateModel>, action: CountObjectsByQuery) {
    return this.databaseClientService.countObjectsByQuery(action.rsql).pipe(
      tap(result => ctx.patchState({totalElementsByQuery: result.count as number }))
    );
  }

}
