import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {Store} from "@ngxs/store";
import {catchError, } from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {ObjectCount, ObjectCountResult} from '../s3-browser.model';
import { S3BrowserConfig } from '../s3-browser.config';
import {CountObjectsByBucket, CountObjectsByQuery} from "../s3-browser.actions";


@Injectable({
  providedIn: 'root'
})
export class DataBaseClientService {
  constructor(
    private store: Store,
    private httpClient: HttpClient,
    private config: S3BrowserConfig
  ) {}


  triggerCountAction(bucket: string, prefix: string) {
    const createJsonString = (s3key: string) => JSON.stringify({ rsql: `s3bucket=='${bucket}';s3key=sw=('${s3key}');content=exists=true;isCurrentVersion==true` });

    const jsonStringWithPrefix = createJsonString(prefix);
    const objWith = JSON.parse(jsonStringWithPrefix);
    this.store.dispatch(this.getCountObjectsByQuery(objWith.rsql));

    const jsonStringWithout = createJsonString('');
    const objWithout = JSON.parse(jsonStringWithout);
    this.store.dispatch(this.getCountObjectsByBucket(objWithout.rsql));
  }


  getCountObjectsByQuery(rsqlString: string): CountObjectsByQuery {
    const objectCount = new ObjectCount(rsqlString);
    return new CountObjectsByQuery(objectCount);

  }

  countObjectsByQuery(query: ObjectCount): Observable<ObjectCountResult> {
    return this.countObjects(query, this.config.objectCountUrl);
  }


  getCountObjectsByBucket(rsqlString: string): CountObjectsByBucket {
    const objectCount = new ObjectCount(rsqlString);
    return new CountObjectsByBucket(objectCount);
  }

  countObjectsByBucket(query: ObjectCount): Observable<ObjectCountResult> {
    return this.countObjects(query, this.config.objectBucketCountUrl);
  }


  countObjects(query: ObjectCount, url: string): Observable<ObjectCountResult> {
    return this.httpClient.post<ObjectCountResult>(url, query).pipe(
      catchError(() => of({ rsql: query.rsql, count: -1 }))
    );
  }

}
