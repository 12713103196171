import {Component, OnInit} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {Store} from "@ngxs/store";
import {I18nState} from "@frontmania/i18n";
import {filter, map, switchMap} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {NotificationType, OpenNotification} from "@frontmania/notification";
import {ChoiceValuesAdminService} from "../../choice-values-admin.service";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import { ChoiceValue, ChoiceValueDefinition, RefreshAllChoiceValues } from '@frontmania/object-master-data';
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {LoadChoiceValueForEditing} from "../../choice-values-admin.actions";
import {ChoiceValuesAdminState} from "../../choice-values-admin.state";
import {NewChoiceValuesModalComponent} from "../helper-dialogs/new-choice-values-modal.component";

@UntilDestroy()
@Component({
  selector: 'frontmania-choice-values-editing',
  templateUrl: './editing.component.html',
  styleUrls: ['./editing.component.scss']
})
export class EditingComponent implements OnInit {

  columns: string[] = ['value', 'en', 'de'];

  canCreateValue = false;
  canUpdateLabel = false;

  choiceValueName: string;
  cvDataSource = new MatTableDataSource();
  dialogRef: MatDialogRef<NewChoiceValuesModalComponent, unknown>;

  constructor(
    private cvService: ChoiceValuesAdminService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private store: Store) {
  }

  ngOnInit(): void {
    this.route.paramMap.pipe(
      untilDestroyed(this),
      filter((params) => !!params.get("choiceValueName")),
      switchMap((params) => {
        this.choiceValueName = params.get("choiceValueName");
        return this.store.dispatch(new LoadChoiceValueForEditing(this.choiceValueName)).pipe(
          map(() => this.store.selectSnapshot(ChoiceValuesAdminState.cvForEditing))
        );
      })
    ).subscribe((cv: ChoiceValueDefinition) => {
      this.cvDataSource = new MatTableDataSource(cv.values);
      this.setPermissions(cv.permissions);
    });
  }

  private setPermissions(permissions: string[]) {
    this.canCreateValue = permissions.includes('CREATE_VALUE');
    this.canUpdateLabel = permissions.includes('UPDATE_LABEL');
  }

  addNewValue(): void {
    this.dialogRef = this.dialog.open(NewChoiceValuesModalComponent, {
      width: '350px',
      data: {
        newValue: undefined
      }
    });

    this.dialogRef.afterClosed().pipe(
      untilDestroyed(this),
      filter((cvValue: string) => !!cvValue),
      map((newValue: string) => {
        const currentCV = this.store.selectSnapshot(ChoiceValuesAdminState.cvForEditing);
        const valAlreadyExists = currentCV.values.filter(val => val.value === newValue).length > 0;
        if (valAlreadyExists) {
          throw Error('choiceValues.editing.duplicateValue');
        }
        return newValue;
      }),
      switchMap((newValue: string) => {
        const body = this.createCVValue(newValue);
        return this.cvService.addValue(this.choiceValueName, body)
      }),
      switchMap(() => {
        return this.store.dispatch(new LoadChoiceValueForEditing(this.choiceValueName))
      })
    ).subscribe(() => {
      this.cvDataSource = new MatTableDataSource(this.store.selectSnapshot(ChoiceValuesAdminState.cvForEditing).values);
      this.store.dispatch(new RefreshAllChoiceValues(this.store.selectSnapshot(I18nState.selectedLocale)));
      this.store.dispatch(new OpenNotification(NotificationType.SUCCESS, 'choiceValues.editing.newValue.success'));

    });
  }

  private createCVValue(newValue: string): ChoiceValue {
    return {
      'value': newValue, 'labels': {
        'de': '',
        'en': '',
      }
    };
  }

  updateLabel(valueName: string, labelName: string, newValue: string) {
    const body = {"language": labelName, "label": newValue};

    this.cvService.updateLabel(this.choiceValueName, valueName, body)
      .pipe(
        untilDestroyed(this),
        switchMap(() => {
          return this.store.dispatch(new LoadChoiceValueForEditing(this.choiceValueName));
        })
      )
      .subscribe(
        () => {
          this.store.dispatch(new RefreshAllChoiceValues(this.store.selectSnapshot(I18nState.selectedLocale)));
          this.store.dispatch(new OpenNotification(NotificationType.SUCCESS, "choiceValues.success.label", {label: labelName}));
        }
      );
  }
}
