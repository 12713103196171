import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FORM_NAME, ObjectMetadata } from '../../object-check-in.model';
import { CheckInMetadataMapper } from '../../service/check-in-metadata-mapper';
import {
  DynamicFormOutput,
  FillStrategyService,
  DynamicFormInput,
  ObjectCheckinConnector
} from '@frontmania/object-master-data';
import { AuthState } from '@frontmania/auth';
import { ObjectCheckInState } from '../../object-check-in.state';
import { filter, switchMap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { ClearCheckinForm, RemoveAll } from '../../object-check-in.actions';
import { ObjectCheckInConfigService } from '../../api/object-check-in-config.service';


@UntilDestroy()
@Component({
  selector: 'frontmania-check-in-form',
  templateUrl: './check-in-form.component.html',
  styleUrls: ['./check-in-form.component.scss']
})
export class CheckInFormComponent implements OnInit, OnDestroy {

  @Output()
  uploadEvent: EventEmitter<ObjectMetadata> = new EventEmitter<ObjectMetadata>();
  @Output()
  resetEvent: EventEmitter<void> = new EventEmitter<void>();

  formDefinitionInput$$: BehaviorSubject<DynamicFormInput> = new BehaviorSubject<DynamicFormInput>(undefined);

  formName: string = FORM_NAME;
  applyFillStrategies$: Observable<boolean>;

  constructor(private store: Store,
              private objectCheckinConnector: ObjectCheckinConnector,
              private objectCheckinConfigService: ObjectCheckInConfigService,
              private fillStrategyService: FillStrategyService) {
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ClearCheckinForm());
  }

  ngOnInit(): void {
    this.applyFillStrategies$ = this.objectCheckinConfigService.applyFillStrategies$$.asObservable();

    // we load all master-data and create the form everytime the templateName changes
    this.store.select(ObjectCheckInState.templateName).pipe(
      untilDestroyed(this),
      filter(templateName => !!templateName),
      switchMap(templateName => this.objectCheckinConnector.getFormDefinition(templateName)),
      withLatestFrom(this.objectCheckinConfigService.applyFillStrategies$$.asObservable())
    ).subscribe(([formDefinition, applyFillStrategies]) => {
      if (formDefinition) {
        const currentUser = this.store.selectSnapshot(AuthState.currentUser);
        if (applyFillStrategies) {
          this.fillStrategyService.apply(formDefinition, currentUser)
        }
        this.formDefinitionInput$$.next(new DynamicFormInput(formDefinition));
      }
    });
  }

  submitForm(formFields: DynamicFormOutput[]) {
    const formMetaData: ObjectMetadata = CheckInMetadataMapper.toCheckinMetadata(formFields);
    this.uploadEvent.emit(formMetaData);
  }

  delegateReset() {
    this.resetEvent.emit();
  }

}
