import {FileEntry, FileUploadStatus, ObjectMetadata} from './object-check-in.model';
import {ObjectAclDefinition} from "@frontmania/object-master-data";


export class AddFile {
  static readonly type = '[ObjetCheckin] AddFile';

  constructor(public fileEntry: FileEntry) {
  }
}

export class RemoveSingleFile {
  static readonly type = '[ObjetCheckin] RemoveSingleFile';

  constructor(public fileEntry: FileEntry) {
  }
}

export class RemoveAll {
  static readonly type = '[ObjetCheckin] DroppedFile.RemoveAll';
  constructor(public successFull = false) {
  }

}

export class ShowObjectCheckInResult {
  static readonly type = '[ObjetCheckin] ShowObjectCheckInResult';

}

export class ClearCheckinForm {
  static readonly type = '[ObjetCheckin] ClearCheckinForm';

}

export class SetObjectCheckInStatus {
  static readonly type = '[ObjetCheckin] SetObjectCheckinStatus';

  constructor(public fileName: string, public status: FileUploadStatus, public statusText: string) {
  }
}

export class InitObjectCheckIn {
  static readonly type = '[ObjetCheckin] InitObjectCheckIn';

  constructor(public language: string, public templateName: string) {
  }
}

export class CheckInObjects {
  static readonly type = '[ObjetCheckin] CheckInObjects';

  constructor(public formMetadata: ObjectMetadata, public acl: ObjectAclDefinition[]) {
  }

}
