import {Injectable} from '@angular/core';
import { DateRangeField, FieldDefinition, DynamicFormOutput, ValueType } from '@frontmania/object-master-data';
import * as moment from "moment";


@Injectable({
  providedIn: 'root'
})
export class CheckInMetadataMapper {

  private static toMoment(value: unknown): moment.Moment {
    if (!value) {
      return undefined;
    }
    if (moment.isMoment(value)) {
      return value as moment.Moment;
    }
    return moment(value, moment.ISO_8601);
  }

  private static isDate(fieldDef: FieldDefinition) {
    return fieldDef.type === ValueType.DATE_TIME || fieldDef.type === ValueType.DATE;
  }

  static toCheckinMetadata(fieldDefinitions: DynamicFormOutput[]) {
    const metadata = {};
    fieldDefinitions.forEach(fieldDef => {

      const fieldName = fieldDef.fieldDefinition.name;
      const fieldValue = fieldDef.value;

      if (CheckInMetadataMapper.isDate(fieldDef.fieldDefinition)) {
        const dateRangeField = fieldValue as DateRangeField;
        const date: moment.Moment = CheckInMetadataMapper.toMoment(dateRangeField?.start);

        if (fieldDef.fieldDefinition.multiValued) {
          metadata[fieldName] = [date?.valueOf()];
        } else {
          metadata[fieldName] = date?.valueOf();
        }
      } else if (fieldDef.fieldDefinition.masterDataSource) {
        if ((fieldValue as string[]).length > 0) {
          metadata[fieldName] = fieldValue;
        }
      } else {
        if (fieldDef.fieldDefinition.multiValued && !fieldDef.fieldDefinition.choiceValuesName) {
          metadata[fieldName] = [fieldValue];
        } else {
          metadata[fieldName] = fieldValue;
        }
      }
    })
    return metadata;
  }

}
