import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {combineLatest, filter, Observable, of, switchMap} from 'rxjs';
import {Store} from '@ngxs/store';
import {I18nState} from '@frontmania/i18n';
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {S3ClientService} from "../../service/s3-client.service";


export interface S3ObjectInfo {
  contentType: string;
  contentSize: number;
  lastModified: Date;
  objectUrl: string;

}

@UntilDestroy()
@Component({
  selector: 'frontmania-s3-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {

  bucket: string;
  key: string;
  s3InfoObject: S3ObjectInfo;

  constructor(
    private store: Store,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private s3ClientService: S3ClientService) {
  }

  ngOnInit(): void {
    this.loadObjectMetadata();
  }

  selectedLanguage(): string {
    return this.store.selectSnapshot(I18nState.selectedLocale)
  }

  private loadObjectMetadata() {
    this.bucket = this.activatedRoute.snapshot.paramMap.get('bucket');
    this.key = this.activatedRoute.snapshot.paramMap.get('prefix');

    const getObjectRequest: AWS.S3.Types.GetObjectRequest = {
      Bucket: this.bucket,
      Key: this.key
    };

    this.s3ClientService.getObject(getObjectRequest).pipe(
      untilDestroyed(this),
      filter((resp) => !!resp),
      switchMap((resp) => {
        return combineLatest([
          of(resp),
          this.getSignedUrl()
        ])
      }),
    ).subscribe(([resp, url]) => {
      this.s3InfoObject = {
        contentType: resp.ContentType,
        contentSize: resp.ContentLength,
        lastModified: resp.LastModified,
        objectUrl: url.substring(0, url.indexOf('?'))
      }
    });
  }

  open(): void {
    this.getSignedUrl().pipe(
      untilDestroyed(this),
      filter((url) => !!url),
    ).subscribe(url => window.open(url, "_blank")); // preview?
  }

  private getSignedUrl(): Observable<string> {
    const params = {Bucket: this.bucket, Key: this.key};
    return this.s3ClientService.getSignedUrl('getObject', params);
  }

  objectUrl() {
    return this.s3InfoObject?.objectUrl;
  }
}
