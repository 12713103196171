import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {ChoiceValuesAdminConfig} from "./choice-values-admin.config";
import { ChoiceValue, ChoiceValueDefinition } from '@frontmania/object-master-data';

const headers = new HttpHeaders({
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  'Accept': 'application/json'
});

@Injectable({
  providedIn: 'root',
})
export class ChoiceValuesAdminService {

  constructor(private http: HttpClient,
              private config: ChoiceValuesAdminConfig) {
  }

  addValue(choiceValueName: string, body: ChoiceValue): Observable<ChoiceValueDefinition> {
    return this.http.post<ChoiceValueDefinition>(this.config.choiceValuesUrl + '/' + choiceValueName + '/value', body, {headers: headers});
  }

  updateLabel(choiceValueName: string, valueName: string, body: { language: string; label: string }): Observable<ChoiceValueDefinition> {
    return this.http.put<ChoiceValueDefinition>(this.config.choiceValuesUrl + '/' + choiceValueName + '/' + valueName + '/label', body,
      {
        headers: headers,
        responseType: "json"
      });
  }

  getCVForEditing(choiceValueName: string) {
    return this.http.get<ChoiceValueDefinition>(this.config.choiceValuesUrl + "/" + choiceValueName + "?language=en&language=de");
  }
}
