import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {LoadChoiceValueForEditing} from "./choice-values-admin.actions";
import {ChoiceValuesAdminService} from "./choice-values-admin.service";
import {tap} from "rxjs";
import {ChoiceValueDefinition} from "@frontmania/object-master-data";

export class ChoiceValuesAdminStateModel {
  cvForEditing: ChoiceValueDefinition;
}

@State<ChoiceValuesAdminStateModel>({
  name: 'cvAdmin',
  defaults: {
    cvForEditing: undefined
  }
})
@Injectable()
export class ChoiceValuesAdminState {

  @Selector()
  static cvForEditing(state: ChoiceValuesAdminStateModel) {
    return state?.cvForEditing;
  }

  constructor(private cvService: ChoiceValuesAdminService) {
  }

  @Action(LoadChoiceValueForEditing)
  loadChoiceValueForEditing(ctx: StateContext<ChoiceValuesAdminStateModel>, action: LoadChoiceValueForEditing) {
    return this.cvService.getCVForEditing(action.name).pipe(
      tap((value) => {
        ctx.patchState({cvForEditing: value});
      })
    );
  }

}
