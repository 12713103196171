import {Observable} from 'rxjs';
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";
import {Injectable} from "@angular/core";
import {S3BrowserConfig} from "../s3-browser.config";
import {S3Credentials} from "../s3-browser.model";


@Injectable({
  providedIn: 'root',
})
export class S3CredentialsLoader {

  constructor(private httpClient: HttpClient, private s3Config: S3BrowserConfig) {
  }

  getCredentials(): Observable<S3Credentials> {
    return this.httpClient
      .get<S3Credentials>(this.s3Config.s3CredentialsUrl + "s3-identity?create=true")
      .pipe(
        map(result => {
          const s3Credentials: S3Credentials = {
            accessKeyId: result.accessKeyId,
            secretAccessKey: result.secretAccessKey
          };
          return s3Credentials;
        })
      );
  }
}
