<div class="layout-container flex flex-col">
  <div mat-dialog-content class="flex flex-col">
    <p>{{'choiceValues.modal.newValue' | translate }}</p>
    <mat-form-field class="w-full">
      <mat-label>{{'choiceValues.modal.hint' | translate}}</mat-label>
      <input data-cy="new-cv-value-input" matInput #inputText [(ngModel)]="data.newValue">
    </mat-form-field>
  </div>

  <div mat-dialog-actions class="flex flex-col">
    <button class="w-full" data-cy="cv-save-button" mat-raised-button color="primary" [disabled]="!isValid(inputText.value)"
            [mat-dialog-close]="data.newValue">{{'choiceValues.modal.controls.btnSave' | translate}}
    </button>
  </div>
</div>
