<mat-dialog-content class="flex flex-col">
  <div>{{'s3Browser.deleteQuestion' | translate}}</div>
  <ul>
    <li *ngFor="let object of data?.objects">{{object.Key}}</li>
  </ul>
</mat-dialog-content>

<mat-dialog-actions>
  <button data-cy="s3-delete-confirm-button" mat-raised-button color="primary" (click)="ok()">{{'s3Browser.delete.controls.ok' | translate}}</button>
</mat-dialog-actions>
