import {Component, OnInit} from '@angular/core';
import {SecurityTemplates} from '@frontmania/auth';
import {UntilDestroy} from '@ngneat/until-destroy';
import {ObjectSearchService} from '@frontmania/object-search';

@UntilDestroy()
@Component({
  selector: 'frontmania-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  showErrorMessage = false;

  constructor(private objectSearchService: ObjectSearchService) {
  }

  ngOnInit(): void {
    this.objectSearchService.clearState();
  }

  get choiceValuesAccess() {
    return SecurityTemplates.CHOICE_VALUES_ADMIN_ACCESS;
  }

  get edlvSearchAccess() {
    return SecurityTemplates.OBJECT_SEARCH_ACCESS;
  }

  get edlvCheckinAccess() {
    return SecurityTemplates.OBJECT_CHECKIN_ACCESS;
  }

  get s3BrowserAccess() {
    return SecurityTemplates.S3BROWSER_ACCESS;
  }

}
