import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {UntilDestroy} from "@ngneat/until-destroy";

export interface DialogData {
  objects: undefined;
  confirmDelete: boolean
}

@UntilDestroy()
@Component({
  templateUrl: './delete-confirm-dialog.component.html',
  styleUrls: ['./delete-confirm-dialog.component.scss']
})
export class DeleteConfirmDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<DeleteConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  ok() {
    this.dialogRef.close(true);
  }

}
