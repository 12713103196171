import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {RouterModule} from '@angular/router';
import {MaterialModule} from '@frontmania/material';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxsModule} from '@ngxs/store';
import {I18nModule, I18nModuleConfig} from '@frontmania/i18n';
import {AuthModule, AuthModuleConfig} from '@frontmania/auth';
import {
  authModuleConfigFactory,
  choiceValuesAdminConfigFactory,
  dynamicFormsConfigFactory,
  i18nModuleConfigFactory,
  notificationModuleConfigFactory,
  objectCheckInConfigFactory,
  objectDetailsConfigFactory,
  objectExportConfigFactory,
  objectSearchConfigFactory,
  objectStoreMasterDataConfigFactory,
  s3BrowserConfigFactory,
  translateModuleConfig,
  userProfileConfigFactory
} from './app.config';
import {TranslateModule} from '@ngx-translate/core';
import {NgxsStoragePluginModule, StorageOption} from '@ngxs/storage-plugin';
import {environment} from '../environments/environment';
import {routes} from './app.routing.module';
import {ChoiceValuesAdminConfig, ChoiceValuesAdminModule} from '@frontmania/choice-values-admin';
import {HomeComponent} from './home/home.component';
import {NotificationConfig, NotificationModule} from '@frontmania/notification';
import {ObjectSearchConfig, ObjectSearchModule} from '@frontmania/object-search';
import {ObjectStoreMasterDataConfig, ObjectStoreMasterDataModule} from '@frontmania/object-master-data';
import {NgxsRouterPluginModule} from '@ngxs/router-plugin';
import {NgxsReduxDevtoolsPluginModule} from '@ngxs/devtools-plugin';
import {NgxsFormPluginModule} from '@ngxs/form-plugin';
import {ObjectCheckInConfig, ObjectCheckInModule} from "@frontmania/object-check-in";
import {S3BrowserConfig, S3BrowserModule} from '@frontmania/s3-browser';
import {ObjectDetailsConfig, ObjectDetailsModule} from "@frontmania/object-details";
import {PageNotFoundComponent} from "./page-not-found/page-not-found.component";
import {DynamicFormsConfig} from "@frontmania/dynamic-forms";
import {ObjectExportConfig} from "@frontmania/object-export";
import {UserProfileConfig, UserProfileModule} from "@frontmania/user-profile";
import {TileComponent} from './home/tile/tile.component';
import { MaintenanceInfoModule } from '@frontmania/maintenance-info';

@NgModule({
  declarations: [AppComponent, HomeComponent, PageNotFoundComponent, TileComponent],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes, {
      enableTracing: false
    }),
    BrowserAnimationsModule,
    MaterialModule,
    NgxsModule.forRoot([], {
      developmentMode: !environment.production
    }),
    NgxsStoragePluginModule.forRoot({
      storage: StorageOption.SessionStorage
    }),
    NgxsRouterPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsFormPluginModule.forRoot(),
    AuthModule.forRoot(),
    I18nModule.forRoot(),
    TranslateModule.forRoot(translateModuleConfig),
    ObjectStoreMasterDataModule.forRoot(),
    ChoiceValuesAdminModule.forRoot(),
    ObjectSearchModule,
    ObjectCheckInModule,
    ObjectDetailsModule,
    NotificationModule.forRoot(),
    S3BrowserModule.forRoot(),
    UserProfileModule,
    RouterModule,
    MaintenanceInfoModule
  ],
  providers: [

    {
      provide: AuthModuleConfig,
      useFactory: authModuleConfigFactory
    },
    {
      provide: ObjectSearchConfig,
      useFactory: objectSearchConfigFactory,
    },
    {
      provide: ObjectStoreMasterDataConfig,
      useFactory: objectStoreMasterDataConfigFactory
    },
    {
      provide: I18nModuleConfig,
      useFactory: i18nModuleConfigFactory
    },
    {
      provide: DynamicFormsConfig,
      useFactory: dynamicFormsConfigFactory
    },
    {
      provide: NotificationConfig,
      useFactory: notificationModuleConfigFactory
    },
    {
      provide: ObjectCheckInConfig,
      useFactory: objectCheckInConfigFactory
    },
    {
      provide: ObjectDetailsConfig,
      useFactory: objectDetailsConfigFactory
    },
    {
      provide: ObjectExportConfig,
      useFactory: objectExportConfigFactory
    },
    {
      provide: ObjectSearchConfig,
      useFactory: objectSearchConfigFactory,
    },
    {
      provide: ChoiceValuesAdminConfig,
      useFactory: choiceValuesAdminConfigFactory,
    },
    {
      provide: UserProfileConfig,
      useFactory: userProfileConfigFactory,
    },
    {
      provide: S3BrowserConfig,
      useFactory: s3BrowserConfigFactory
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
