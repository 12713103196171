<fm-page>
  <fm-titlebar fmTitlebar>{{'objectCheckIn.title' | translate}}</fm-titlebar>
  <div fmContent class="layout-container">

    <div class="drop-zone-wrapper fm-background-container">
      <ngx-file-drop [disabled]="isUploadInProgress" multiple="true" (onFileDrop)="addFile($event)">
        <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
          <div class="flex flex-col justify-center items-center">
            <span>{{'objectCheckIn.fileDropZoneLabel' | translate}}</span>
            <button [disabled]="isUploadInProgress" mat-raised-button color="primary" (click)="openFileSelector()">{{'objectCheckIn.fileButtonLabel' | translate}}</button>
          </div>
        </ng-template>
      </ngx-file-drop>

      <div class="chiplist">
        <mat-chip-listbox>
          <mat-chip-option class="filename-chip"
                    [class.success]="isUploadStateSuccess(file.uploadStatus)"
                    [class.in-progress]="isUploadStateInProgress(file.uploadStatus)"
                    [class.error]="isUploadStateError(file.uploadStatus)"
                    *ngFor="let file of files$ | async; let i=index"
                    [selectable]="!isUploadInProgress"
                    [removable]="!isUploadInProgress"
                    (removed)="removeFile(file)">
            {{ file.fileName }}
            <mat-icon *ngIf="!isUploadInProgress" matChipRemove>cancel</mat-icon>
          </mat-chip-option>
        </mat-chip-listbox>
      </div>
    </div>
    <div class="fm-background-container">
      <mat-tab-group >
        <mat-tab [label]="'objectDetails.tabs.document-properties' | translate">
          <frontmania-check-in-form (uploadEvent)="submitForm($event)" (resetEvent)="reset()">

          </frontmania-check-in-form>
        </mat-tab>
        <mat-tab [label]="'objectDetails.tabs.permissions' | translate" *ngIf="(permissionConfig$$.asObservable() | async).length > 0">
          <frontmania-acl-editor
            [aclData$]="initialAcl$$.asObservable()"
            [reset$]="resetButtonDisabled$$.asObservable()"
            (updateAcl)="setCurrentAcl($event)">
          </frontmania-acl-editor>
        </mat-tab>
      </mat-tab-group>

      <div class="button-area">
        <frontmania-dynamic-form-control-buttons
          [resetButtonDisabled$]="resetButtonDisabled$$.asObservable()"
          [actionButtonsDisabled$]="disableSendButton$$.asObservable()">
        </frontmania-dynamic-form-control-buttons>
      </div>

    </div>

  </div>
</fm-page>


