<fm-page>
  <fm-titlebar fmTitlebar>
    <div class="button-area flex flex-col sm:flex-row gap-2 justify-between items-center">
      <div class="flex flex-col sm:flex-row gap-2 justify-between items-start">
        <span>{{'s3browser.title' | translate}}</span>
      </div>
      <div class="flex flex-col sm:flex-row gap-2 justify-end">
        <button data-cy="s3-upload-start-button" mat-button [disabled]="isStartUploadButtonDisabled() || (loading$ | async)" (click)="startUpload()">
          <mat-icon>play_circle_filled</mat-icon>
          {{'s3browser.startUpload' | translate}}
        </button>

        <button mat-button [disabled]="isClearButtonDisabled()" (click)="clearList(false)">
          <mat-icon>clear_all</mat-icon>
          {{'s3browser.clear' | translate}}
        </button>
      </div>
    </div>
  </fm-titlebar>
  <div fmContent>
    <frontmania-s3-breadcrumbs></frontmania-s3-breadcrumbs>

    <div class="drop-zone-wrapper fm-background-container">
      <ngx-file-drop [disabled]="loading$ | async"  multiple="true" (onFileDrop)="addFile($event)">
        <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
          <div class="flex flex-col justify-center items-center">
            <span>{{'s3browser.upload.fileDropZoneLabel' | translate}}</span>
            <button [disabled]="loading$ | async" mat-raised-button color="primary" (click)="openFileSelector()">{{'s3browser.upload.fileButtonLabel' | translate}}</button>
          </div>
        </ng-template>
      </ngx-file-drop>
    </div>

    <table mat-table [dataSource]="uploads$" class="mat-elevation-z0">

      <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef>{{'s3Browser.upload.table.header.state' | translate}}</th>
        <td mat-cell *matCellDef="let upload">
          <mat-icon *ngIf="upload.state === 0"></mat-icon>
          <mat-icon color="primary" class="p-0" *ngIf="upload.state === 1">loop</mat-icon>
          <mat-icon class="p-0 success" *ngIf="upload.state === 2">done</mat-icon>
          <mat-icon color="warn" class="p-0" *ngIf="upload.state === 3">error</mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="path">
        <th mat-header-cell *matHeaderCellDef>{{'s3Browser.upload.table.header.path' | translate}}</th>
        <td mat-cell *matCellDef="let upload">{{ upload.path }}</td>
      </ng-container>

      <ng-container matColumnDef="size">
        <th mat-header-cell *matHeaderCellDef>{{'s3Browser.upload.table.header.size' | translate}}</th>
        <td mat-cell *matCellDef="let upload">{{ upload.size | fileSize : selectedLanguage() }}</td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>{{'s3Browser.upload.table.header.type' | translate}}</th>
        <td mat-cell *matCellDef="let upload">{{ upload.type }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

  </div>
</fm-page>

