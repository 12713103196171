import {Component, OnInit} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {filter, Observable} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {I18nState} from '@frontmania/i18n';
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {Upload} from 'aws-sdk/clients/devicefarm';
import {S3BrowserState} from "../../s3-browser-state";
import {AddUploadFile, ClearUploadList, SetUploadTarget, StartUpload, StopUpload} from '../../s3-browser.actions';
import {FrontmaniaS3ObjectBuilder, S3FileEntry, UploadStateType} from "../../s3-browser.model";
import {finalize} from "rxjs/operators";
import {NotificationType, OpenNotification} from "@frontmania/notification";
import {FileSystemFileEntry, NgxFileDropEntry} from "ngx-file-drop";

@UntilDestroy()
@Component({
  selector: 'frontmania-s3-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {

  @Select(S3BrowserState.uploads) uploads$: Observable<Upload[]>;
  @Select(S3BrowserState.isUploading) loading$: Observable<boolean>;

  displayedColumns: string[] = ['state', 'path', 'size', 'type'];

  constructor(private store: Store,
              private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.clearList(false);
    this.activatedRoute.paramMap
      .pipe(untilDestroyed(this),
        filter((paramMap) => !!paramMap))
      .subscribe((paramMap) => {
        const bucket: string = paramMap.get('bucket');
        const prefix: string = paramMap.get('prefix');
        const target = FrontmaniaS3ObjectBuilder.toUploadTarget(bucket, prefix);
        this.store.dispatch(new SetUploadTarget(target));
      });
  }

  startUpload(): void {
    this.store.dispatch(new StartUpload()).pipe(
      untilDestroyed(this),
      finalize(() => {
        this.store.dispatch(new StopUpload());
      })
    ).subscribe(() => {
      const uploads = this.store.selectSnapshot(S3BrowserState.uploads);
      const noErrors = uploads.filter(upload => upload.state === UploadStateType.ERROR).length === 0;
      if (!noErrors) {
        this.clearList(true);
        this.store.dispatch(new OpenNotification(NotificationType.ERROR, 's3browser.upload.errors'));
      } else {
        this.clearList(false);
        this.store.dispatch(new OpenNotification(NotificationType.SUCCESS, 's3browser.upload.success'));

      }

    });
  }

  clearList(keepFailed: boolean): void {
    this.store.dispatch(new ClearUploadList(keepFailed));
  }

  isStartUploadButtonDisabled(): boolean {
    const uploads = this.store.selectSnapshot(S3BrowserState.uploads)
    return uploads === undefined || uploads.length === 0;
  }

  isClearButtonDisabled(): boolean {
    return this.isStartUploadButtonDisabled();
  }

  addFile(files: NgxFileDropEntry[]) {

    for (const addedFile of files) {

      if (!addedFile.fileEntry.isFile) {
        console.error(addedFile.relativePath + ' is not a file');
        continue;
      }

      (addedFile.fileEntry as FileSystemFileEntry).file((actualFile: File) => {
        if (!this.isNewFile(actualFile)) {
          this.store.dispatch(new OpenNotification(NotificationType.ERROR, "objectCheckIn.errors.duplicateFile", {fileName: actualFile.name}))
        } else if (actualFile.size === 0) {
          this.store.dispatch(new OpenNotification(NotificationType.ERROR, "objectCheckIn.errors.zeroByteFile", {fileName: actualFile.name}))
        } else {
          const fileEntry: S3FileEntry = {
            file: actualFile,
            fileName: actualFile.name
          };
          this.store.dispatch(new AddUploadFile(fileEntry));
        }
      });
    }
  }

  private isNewFile(addedFile: File) {
    const filesInStore = this.store.selectSnapshot(S3BrowserState.uploads);
    return filesInStore.filter(file => file.path === addedFile.name).length !== 1;
  }

  selectedLanguage() {
    return this.store.selectSnapshot(I18nState.selectedLocale)
  }

}
