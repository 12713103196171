import {ObjectDetails} from "@frontmania/object-master-data";

export const DELIMITER = "/";

export interface S3FileEntry {
    file: File;
    fileName: string;
}

export interface S3BrowserDirectory {
    name: string;
    files: File[];
    directories: S3BrowserDirectory[];
}

export enum S3ObjectType {
    ROOT,
    BUCKET,
    FOLDER,
    FILE
}

export class FrontmaniaS3Object {
    private _bucket: string;
    private _key: string;
    private _name: string;
    private _type: S3ObjectType;
    private _size: number;
    private _modified: Date;

    constructor(
        bucket: string,
        key: string,
        name: string,
        type: S3ObjectType,
        size: number,
        modified: Date,
    ) {
        this._bucket = bucket;
        this._key = key;
        this._name = name;
        this._type = type;
        this._size = size;
        this._modified = modified;
    }

    public get bucket(): string {
        return this._bucket;
    }

    public get key(): string {
        return this._key;
    }

    public get name(): string {
        return this._name;
    }

    public get size(): number {
        return this._size;
    }

    public get modified(): Date {
        return this._modified;
    }

    public get type(): S3ObjectType {
        return this._type;
    }

    get isRoot(): boolean {
        return this._type != null && this._type === S3ObjectType.ROOT;
    }

    get isBucket(): boolean {
        return this._type != null && this._type === S3ObjectType.BUCKET;
    }

    get isFolder(): boolean {
        return this._type != null && this._type === S3ObjectType.FOLDER;
    }

    get isFile(): boolean {
        return this._type != null && this._type === S3ObjectType.FILE;
    }
}

export class FrontmaniaS3ObjectBuilder {

    private _bucket: string;
    private _key: string;
    private _name: string;
    private _type: S3ObjectType;
    private _size: number;
    private _modified: Date;

    static toUploadTarget(bucket: string, key: string): FrontmaniaS3Object {
        let s3ObjectType: S3ObjectType;
        let name: string;
        if (bucket && !key) {
            s3ObjectType = S3ObjectType.BUCKET;
            name = bucket;
        } else if (bucket && key) {
            if (key.endsWith(DELIMITER)) {
                s3ObjectType = S3ObjectType.FOLDER;
            } else {
                s3ObjectType = S3ObjectType.FILE;
            }
            const keyParts: string[] = key.split(DELIMITER).filter(part => part.length > 0);
            name = keyParts[keyParts.length - 1];
        } else {
            throw new Error('Unsupported S3 object builder state');
        }
        return new FrontmaniaS3Object(bucket, key, name, s3ObjectType, null, null);
    }

    bucket(bucket: string): this {
        this._bucket = bucket;
        return this;
    }

    key(key: string): this {
        this._key = key;
        return this;
    }

    size(size: number): this {
        this._size = size;
        return this;
    }

    name(name: string): this {
        this._name = name;
        return this;
    }

    type(type: S3ObjectType): this {
        this._type = type;
        return this;
    }

    modified(modified: Date): this {
        this._modified = modified;
        return this;
    }

    build(): FrontmaniaS3Object {
        return new FrontmaniaS3Object(this._bucket, this._key, this._name, this._type, this._size, this._modified);
    }

}


export enum UploadStateType {
    PENDING, RUNNING, SUCCESS, ERROR
}

export interface Upload {
    state: UploadStateType;
    path: string;
    file: File;
    size: number;
    type: string;
}

export interface S3BrowserStateModel {
    target: FrontmaniaS3Object;
    uploads: Upload[];
    isUploading: boolean;
    currentUpload: Upload;
    credentials: S3Credentials;
    breadCrumbs: Breadcrumb[];
    currentPage: number;
    currentPageSize: number;
    totalElements?: number;
    totalElementsByQuery: number;
    totalElementsByBucket: number;
    loadedDocuments: ObjectDetails[];
}

export const STATE_DEFAULTS = {
    breadCrumbs: [],
    target: null,
    uploads: [],
    isUploading: false,
    currentUpload: undefined,
    credentials: undefined,
    totalElements: undefined,
    totalElementsByQuery: undefined,
    totalElementsByBucket: undefined,
    loadedDocuments: undefined,
    currentPage: 0,
    currentPageSize: 100
}

export interface S3Credentials {
    accessKeyId: string;
    secretAccessKey: string;
}

export class BrowserTableRow {

    private _s3Object: FrontmaniaS3Object;

    constructor(s3Object: FrontmaniaS3Object) {
        this._s3Object = s3Object;
    }

    get s3Object(): FrontmaniaS3Object {
        return this._s3Object;
    }

}

export interface Breadcrumb {
    bucket: string;
    key: string;
    name: string;
    type: S3ObjectType;
}

export class ObjectCount {
  constructor(public rsql: string) {}
}

export interface ObjectCountResult {
  rsql: string;
  count: number;
}
