import {FrontmaniaS3Object, S3FileEntry, ObjectCount} from "./s3-browser.model";

export class BrowseTo {
  static readonly type = '[S3Browser] Browse To';
  constructor(public s3Object: FrontmaniaS3Object) {
  }
}

export class LoadS3Credentials {
  static readonly type = '[S3Browser] RegisterUpload';
}

export class SetUploadTarget {
  static readonly type = '[S3Browser] Set Target';

  constructor(public target: FrontmaniaS3Object) {
  }
}

export class AddUploadFile {
  static readonly type = '[S3Browser] Add File';
  constructor(public fileEntry: S3FileEntry) {
  }
}

export class StopUpload {
  static readonly type = '[S3Browser] Stop';
}

export class StartUpload {
  static readonly type = '[S3Browser] Start';
}

export class ClearUploadList {
  static readonly type = '[S3Browser] Clear List';
  constructor(public keepFailed: boolean) {
  }
}

export class CountObjectsByQuery {
  static readonly type = '[ObjectSearch] CountObjectsByQuery'
  constructor(public rsql: ObjectCount) {}
}

export class CountObjectsByBucket {
  static readonly type = '[ObjectSearch] CountObjectsByBucket'
  constructor(public rsql: ObjectCount) {}
}
