import { Component, OnDestroy, OnInit } from '@angular/core';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { Select, Store } from '@ngxs/store';
import { FileEntry, FileUploadStatus, FORM_NAME, ObjectMetadata } from '../object-check-in.model';
import {
  AddFile,
  CheckInObjects,
  ClearCheckinForm,
  InitObjectCheckIn,
  RemoveAll,
  RemoveSingleFile,
  ShowObjectCheckInResult
} from '../object-check-in.actions';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { ObjectCheckInState } from '../object-check-in.state';
import { ActivatedRoute } from '@angular/router';
import { NotificationType, OpenNotification } from '@frontmania/notification';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { I18nState } from '@frontmania/i18n';
import { ObjectAclDefinition, ObjectCheckinConnector } from '@frontmania/object-master-data';
import { concatMap, filter, finalize, switchMap, tap } from 'rxjs/operators';
import { ResetForm } from '@ngxs/form-plugin';

@UntilDestroy()
@Component({
  selector: 'frontmania-object-check-in',
  templateUrl: './object-check-in.component.html',
  styleUrls: ['./object-check-in.component.scss']
})
export class ObjectCheckInComponent implements OnInit, OnDestroy {

  @Select(ObjectCheckInState.files) files$: Observable<FileEntry[]>;
  initialAcl$$: BehaviorSubject<ObjectAclDefinition[]> = new BehaviorSubject<ObjectAclDefinition[]>(undefined);
  permissionConfig$$: BehaviorSubject<string[]> = new BehaviorSubject([]);
  disableSendButton$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  resetButtonDisabled$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  currentAcl: ObjectAclDefinition[];
  isUploadInProgress = false;

  constructor(private store: Store,
              private route: ActivatedRoute,
              private objectCheckinConnector: ObjectCheckinConnector) {
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ClearCheckinForm());
  }

  ngOnInit(): void {
    this.route.paramMap.pipe(
      untilDestroyed(this),
      tap(() => this.clearFiles()), // we always need to remove all files because after a page reload/navigation the file handles are no longer accessible
      switchMap((paramMap) => {
        const templateName = paramMap.get('templateName');
        const language = this.store.selectSnapshot(I18nState.selectedLocale);
        const previousTemplateName = this.store.selectSnapshot(ObjectCheckInState.templateName);
        if (previousTemplateName !== templateName) {
          return this.store.dispatch(new InitObjectCheckIn(language, templateName)).pipe(
            untilDestroyed(this),
            switchMap(() => of(templateName))
          );
        }
        return of(templateName)
      }),
      concatMap((templateName) =>
        combineLatest([
          this.objectCheckinConnector.getDefaultInstanceSecurity([templateName]),
          this.objectCheckinConnector.getCheckInOptions(templateName)
        ])
      ),
      filter(([, options]) => options?.permissions?.length > 0)
    ).subscribe(([aclData, options]) => {
      this.initialAcl$$.next(aclData);
      this.permissionConfig$$.next(options.permissions);
      this.disableSendButton$$.next(true)
    });

    this.files$
      .pipe(
        untilDestroyed(this),
        filter((files: FileEntry[]) => !!files),
      )
      .subscribe((files: FileEntry[]) => {
        if (files.length === 1 && !this.isUploadInProgress) {
          this.disableSendButton$$.next(false);
        } else if (files.length < 1) {
          this.disableSendButton$$.next(true);
        }
      });
  }

  addFile(files: NgxFileDropEntry[]) {

    for (const addedFile of files) {

      if (!addedFile.fileEntry.isFile) {
        console.error(addedFile.relativePath + ' is not a file');
        continue;
      }

      (addedFile.fileEntry as FileSystemFileEntry).file((actualFile: File) => {
        if (!this.isNewFile(actualFile)) {
          this.store.dispatch(new OpenNotification(NotificationType.ERROR, "objectCheckIn.errors.duplicateFile", {fileName: actualFile.name}))
        } else if (actualFile.size === 0) {
          this.store.dispatch(new OpenNotification(NotificationType.ERROR, "objectCheckIn.errors.zeroByteFile", {fileName: actualFile.name}))
        } else {
          const fileEntry: FileEntry = {
            file: actualFile,
            fileName: actualFile.name
          };
          this.store.dispatch(new AddFile(fileEntry)).pipe(untilDestroyed(this)).subscribe(() => this.checkDisabledButton());
        }
      });
    }
  }

  submitForm(formMetadata: ObjectMetadata) {
    this.startUploading();
    this.store.dispatch(new CheckInObjects(formMetadata, this.currentAcl)).pipe(
      untilDestroyed(this),
      finalize(() => {
        this.store.dispatch(new ShowObjectCheckInResult());
        this.stopUploading();
      })
    ).subscribe(() => {
      const files = this.store.selectSnapshot(ObjectCheckInState.files);
      const noErrors = files.filter(file => file.uploadStatus === FileUploadStatus.ERROR).length === 0;
      if (noErrors) {
        this.reset();
      } else {
        this.store.dispatch(new RemoveAll(true));
      }
    });
  }

  clearFiles() {
    this.store.dispatch(new RemoveAll());
  }

  private clearAcl() {
    this.currentAcl = undefined;
  }

  private startUploading() {
    this.isUploadInProgress = true;
    this.sendDisabled(true);
  }

  private stopUploading() {
    this.isUploadInProgress = false;
    this.sendDisabled(false);
  }

  private sendDisabled(val: boolean) {
    this.disableSendButton$$.next(val);
    this.resetButtonDisabled$$.next(val);
  }

  private isNewFile(addedFile: File) {
    const filesInStore = this.store.selectSnapshot(ObjectCheckInState.files);
    return filesInStore.filter(file => file.fileName === addedFile.name).length !== 1;
  }

  removeFile(fileEntry: FileEntry) {
    this.store.dispatch(new RemoveSingleFile(fileEntry)).subscribe(() => this.checkDisabledButton());
  }

  isUploadStateSuccess(status: FileUploadStatus) {
    return status === FileUploadStatus.SUCCESS;
  }

  isUploadStateInProgress(status: FileUploadStatus) {
    return status === FileUploadStatus.IN_PROGRESS;
  }

  isUploadStateError(status: FileUploadStatus) {
    return status === FileUploadStatus.ERROR;
  }

  private checkDisabledButton() {
    const fileCount = this.store.selectSnapshot(ObjectCheckInState.files).length;

    if (fileCount === 0) {
      this.disableSendButton$$.next(true)
    }
    // Button is enabled after the first file is added. Avoids calling this action for every added file.
    if (fileCount === 1) {
      this.disableSendButton$$.next(false)
    }
  }

  setCurrentAcl(currentAcl: ObjectAclDefinition[]) {
    this.currentAcl = currentAcl;
  }

  reset() {
    this.clearFiles();
    this.clearAcl();
    this.store.dispatch(new ResetForm({path: FORM_NAME}))
  }
}
