import {Component, Input} from '@angular/core';

@Component({
  selector: 'frontmania-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.scss']
})

export class TileComponent {

  @Input()
  tileImageUrl: string;
}
