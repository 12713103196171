import {Injectable} from '@angular/core';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {ObjectCheckInConfig} from '../object-check-in.config';
import {
  FileEntry,
  FileUploadData,
  FileUploadStatus,
  ObjectCheckInResponse,
  ObjectMetadata
} from '../object-check-in.model';
import {ObjectAclDefinition} from "@frontmania/object-master-data";
import {FileHashService} from "./file-hash.service";
import {Observable, of} from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class ObjectCheckInService {

  private static buildFormData(fileUploadData: FileUploadData) {
    const formData = new FormData();
    formData.append('document', JSON.stringify(fileUploadData.document));
    formData.append('file', fileUploadData.file.file, encodeURIComponent(fileUploadData.file.fileName));
    formData.append('hashes', JSON.stringify(fileUploadData.hashes));

    return formData;
  }

  private static toSuccess(fileEntry: FileEntry): ObjectCheckInResponse {
    return {
      status: FileUploadStatus.SUCCESS,
      fileName: fileEntry.fileName
    } as ObjectCheckInResponse;
  }

  private static toError(fileEntry: FileEntry, error: Error): ObjectCheckInResponse {
    return {
      status: FileUploadStatus.ERROR,
      fileName: fileEntry.fileName,
      statusText: error.message
    } as ObjectCheckInResponse;
  }

  private static toFileUploadData(selectedAcl: ObjectAclDefinition[], templateName: string, objectMetaData: ObjectMetadata, fileEntry: FileEntry, hash: string): FileUploadData {
    const fileUploadData = {
      document: {
        "classDefinitionName": templateName,
        "properties": {...objectMetaData, documentTitle: fileEntry.fileName}
      },
      hashes: {
        "md5": hash
      },
      file: fileEntry
    } as FileUploadData;
    if (!!selectedAcl && selectedAcl.length > 0) {
      fileUploadData.document["acl"] = selectedAcl;
    }
    return fileUploadData
  }

  constructor(
    private httpClient: HttpClient,
    private config: ObjectCheckInConfig,
    private fileHashService: FileHashService) {
  }

  checkInObject(selectedAcl: ObjectAclDefinition[], objectMetaData: ObjectMetadata, fileEntry: FileEntry, templateName: string): Observable<ObjectCheckInResponse> {
    return of(fileEntry).pipe(
      mergeMap((entry) => this.hashFile(entry.file)),
      map(hash => ObjectCheckInService.buildFormData(ObjectCheckInService.toFileUploadData(selectedAcl, templateName, objectMetaData, fileEntry, hash))),
      mergeMap(uploadData => this.httpClient.post<FileEntry>(this.config.objectCheckInUrl, uploadData)),
      map(() => ObjectCheckInService.toSuccess(fileEntry)),
      catchError(error => of(ObjectCheckInService.toError(fileEntry, error)))
    );
  }

  private hashFile(file: File): Observable<string> {
    if (file instanceof Blob) {
      return this.fileHashService.calculateFileContentHash(file);
    } else {
      throw (new Error('This is no file!'));
    }
  }

}
