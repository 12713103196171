<fm-page>
  <div fmContent>
    <div class="background"></div>
    <div class="flex flex-row justify-center" *ngIf="!showErrorMessage">
      <div class="content flex flex-col sm:flex-row sm:flex-wrap justify-around">

        <frontmania-tile data-cy="tile-logistic-search" class="basis-full sm:basis-1/5" *authVisibleFor="edlvSearchAccess"
                         routerLink="/object-search/logisticsDisposalServiceProviderContracts"
                         tileImageUrl="/assets/images/search_sm.jpeg">
          {{'dashboard.edlvSearch'| translate}}
        </frontmania-tile>

        <frontmania-tile data-cy="tile-logistic-checkIn" class="basis-full sm:basis-1/5" *authVisibleFor="edlvCheckinAccess"
                         routerLink="/object-check-in/logisticsDisposalServiceProviderContracts"
                         tileImageUrl="/assets/images/upload_sm.jpeg">
          {{'dashboard.edlvCheckin'| translate}}
        </frontmania-tile>

        <frontmania-tile data-cy="tile-cv-admin" class="basis-full sm:basis-1/5" *authVisibleFor="choiceValuesAccess"
                         routerLink="/choice-values-admin/logisticsDisposalServiceProviderContracts"
                         tileImageUrl="/assets/images/choice_values_sm.jpeg">
          {{'dashboard.choiceValuesAdmin'| translate}}
        </frontmania-tile>

        <frontmania-tile data-cy="tile-s3-browser" class="basis-full sm:basis-1/5" *authVisibleFor="s3BrowserAccess"
                         routerLink="/s3/browser"
                         tileImageUrl="/assets/images/s3_sm.jpeg">
          {{'dashboard.s3Browser'| translate}}
        </frontmania-tile>

      </div>
    </div>
    <div *ngIf="showErrorMessage">
      <h2>{{'home.errorMessage' | translate}}</h2>
    </div>
  </div>
</fm-page>



