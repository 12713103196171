<fm-maintenance-page *ngIf="maintenanceRunning;"></fm-maintenance-page>
<div *ngIf="!maintenanceRunning;" class="application flex flex-col">
  <fm-maintenance-info-banner [locale]="selectedLocale$ | async"></fm-maintenance-info-banner>
  <fm-toolbar
    [exportAdminViewEnabled]="exportAdminViewEnabled"
    [userName]="(currentUser$ | async)?.fullName"
    [selectedLocale]="selectedLocale$ | async"
    [availableLocales]="availableLocales$ | async"
    [logoutButtonVisible]="logoutButtonVisible"
    (logoutEvent)="logout()"
    (languageSelectEvent)="selectLocale($event)">
    <div leftToolbarPart class="flex flex-row justify-start items-center">
      <mat-icon>local_shipping</mat-icon>
      <div class="flex flex-col">
        <a routerLink="/">
          <span>{{ "applicationName" | translate }}</span>
        </a>
      </div>
    </div>
  </fm-toolbar>

  <div class="app-layout-container flex-auto" >
    <router-outlet></router-outlet>
  </div>
</div>

