import {Injectable} from "@angular/core";

@Injectable()
export class S3BrowserConfig {
  s3EndpointUrl: string;
  s3CredentialsUrl: string;
  objectSearchUrl: string;
  objectCountUrl: string;
  objectBucketCountUrl: string;
}
