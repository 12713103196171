import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {FrontmaniaS3Object} from "../../../s3-browser.model";

export interface CreateFolderDialogData {
  s3Object: FrontmaniaS3Object,
  newFolderName: string;
}

@Component({
  selector: 'frontmania-dialog-create-folder',
  templateUrl: 'create-folder-dialog.component.html',
})
export class CreateFolderDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: CreateFolderDialogData) {
  }

  isValid(value: string) {
    return /^[a-zA-Z_\d-]+$/.test(value);
  }
}
