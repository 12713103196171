<h1 mat-dialog-title>{{'s3browser.createFolder.title' | translate}}</h1>
<div mat-dialog-content>
  <mat-form-field style="width: 100%">
    <mat-label>{{'s3browser.createFolder.folderName' | translate}}</mat-label>
    <input data-cy="s3-create-folder-input" #folderName matInput [(ngModel)]="data.newFolderName">
  </mat-form-field>
</div>
<div mat-dialog-actions class="flex flex-row justify-between">
  <button data-cy="s3-create-folder-confirm-button" mat-raised-button color="primary" type="submit"
          [mat-dialog-close]="data" [disabled]="!isValid(folderName.value)">{{'s3browser.createFolder.controls.ok' | translate}}
  </button>
</div>
