import {Component, OnInit} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {ActivatedRoute} from "@angular/router";
import {Store} from "@ngxs/store";
import {ChoiceValuesAdminService} from "../../choice-values-admin.service";
import {ChoiceValueDefinition, ChoiceValuesAdminConnector} from "@frontmania/object-master-data";
import {I18nState} from "@frontmania/i18n";
import {InitObjectSearch} from "@frontmania/object-search";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {concatMap, filter, map, switchMap} from "rxjs";
import {LoadingService} from '@frontmania/material';

@UntilDestroy()
@Component({
  selector: 'frontmania-choice-values-listing',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.scss']
})
export class ListingComponent implements OnInit {

  isLoading$ = this.loadingService.isLoading();

  choiceValuesDataSource: MatTableDataSource<ChoiceValueDefinition>;
  displayedColumns: string[] = ['name'];

  constructor(private cvService: ChoiceValuesAdminService,
              private choiceValueAdminConnector: ChoiceValuesAdminConnector,
              private activatedRoute: ActivatedRoute,
              private loadingService: LoadingService,
              private store: Store) {
  }

  ngOnInit() {
    this.activatedRoute.paramMap
      .pipe(
        untilDestroyed(this),
        switchMap((params) => {
          const templateName = params.get('templateName');
          const language = this.store.selectSnapshot(I18nState.selectedLocale);
          return this.store.dispatch(new InitObjectSearch(language, templateName)).pipe(
            map(() => templateName)
          );
        }),
        concatMap((templateName) => {
          return this.choiceValueAdminConnector.getChoiceValues([templateName]);
        }),
        filter((cvArray) => !!cvArray)
      ).subscribe((cvArray: ChoiceValueDefinition[]) => {
      const sortedArray = this.sortByName(cvArray);
      this.choiceValuesDataSource = new MatTableDataSource<ChoiceValueDefinition>(sortedArray);
    });

  }

  private sortByName(cvArray: ChoiceValueDefinition[]) {
    return cvArray.sort((cv1, cv2) => (cv1.name > cv2.name) ? 1 : ((cv2.name > cv1.name) ? -1 : 0));
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.choiceValuesDataSource.filter = filterValue.trim().toLowerCase();
  }

}
