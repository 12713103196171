export const FORM_NAME = 'objectCheckIn.checkinForm';

export interface ObjectCheckInStateModel {
  templateName: string;
  files: Array<FileEntry>;
  checkinForm: unknown;
}

export interface FileEntry {
  file?: File;
  fileName: string;
  checksum?: string;
  uploadStatus?: FileUploadStatus;
  uploadErrorMessage?: string;
}

export interface ObjectCheckInResponse {
  status: FileUploadStatus;
  fileName: string;
  statusText?: string;
}

export enum FileUploadStatus {
  SUCCESS, ERROR, IN_PROGRESS
}

export const STATE_DEFAULTS = {
  files: [],
  templateName: null,
  checkinForm: undefined
}

export interface FileUploadData {
  document: ObjectMetadata;
  hashes: Hashes;
  file: FileEntry;
}

export interface Hashes {
  md5: string
}

export interface ObjectMetadata {
  [key: string]: unknown;
}
