import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import {ObjectCheckInComponent} from './components/object-check-in.component';
import {MaterialModule} from "@frontmania/material";
import {I18nModule} from "@frontmania/i18n";
import {NgxsModule} from "@ngxs/store";
import {NgxFileDropModule} from "ngx-file-drop";
import {CheckInFormComponent} from './components/form/check-in-form.component';
import {ObjectCheckInState} from "./object-check-in.state";
import {RouterModule} from "@angular/router";
import {DynamicFormsModule} from "@frontmania/dynamic-forms";
import { authGuard, SecurityTemplates } from '@frontmania/auth';

@NgModule({
    imports: [CommonModule,
        MaterialModule,
        I18nModule,
        RouterModule.forChild([
            {
                path: 'object-check-in/:templateName',
                component: ObjectCheckInComponent,
                canActivate: [authGuard],
                data: {securityTemplate: SecurityTemplates.OBJECT_CHECKIN_ACCESS}
            }
        ]),
        NgxsModule.forFeature([ObjectCheckInState]),
        NgxFileDropModule,
        DynamicFormsModule
    ],
  declarations: [
    ObjectCheckInComponent,
    CheckInFormComponent]
})
export class ObjectCheckInModule {

}
