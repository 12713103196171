import {Component} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {S3BrowserState} from "../../s3-browser-state";
import {Breadcrumb} from "../../s3-browser.model";
import {Router} from "@angular/router";
import {DataBaseClientService} from "../../service/database-client.service";

@Component({
  selector: 'frontmania-s3-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent {

  @Select(S3BrowserState.breadcrumbs)
  breadcrumbs$: Observable<Breadcrumb[]>;

  private static buildParams(breadcrumb: Breadcrumb) {
    const params = {};
    if (breadcrumb.bucket) {
      params['bucket'] = breadcrumb.bucket;
    }
    if (breadcrumb.key) {
      params['prefix'] = breadcrumb.key;
    }
    return params;
  }

  constructor(private store: Store,
              private router: Router,
              private databaseClientService: DataBaseClientService) {
  }

  browseTo(breadcrumb: Breadcrumb) {
    const params = BreadcrumbsComponent.buildParams(breadcrumb);
    this.router.navigate(['s3/browser', params]);

    const key = breadcrumb.key ? breadcrumb.key : "";
    this.databaseClientService.triggerCountAction(breadcrumb.bucket, key)
  }

}
