<div class="breadcrumb-container" *ngIf="(breadcrumbs$ | async)?.length > 0">
  <div class="breadcrumb flex flex-row">
    <ol data-cy="breadcrumbs-list">
      <li *ngFor="let breadcrumb of (breadcrumbs$ | async)">
        <a *ngIf="breadcrumb.type !== 3" (click)="browseTo(breadcrumb)">{{ breadcrumb.name }}</a>
        <span *ngIf="breadcrumb.type === 3" (click)="browseTo(breadcrumb)">{{ breadcrumb.name }}</span>
      </li>
    </ol>
  </div>
</div>
