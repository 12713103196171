import {S3BrowserDirectory, Upload, UploadStateType} from "../s3-browser.model";

export class UploadsHelper {
  static buildUploads(directory: S3BrowserDirectory): Upload[] {
    if (!directory) {
      return [];
    }
    const uploads: Upload[] = [];
    const fillUploads = function (dir: S3BrowserDirectory, path: string) {
      dir.files.forEach((file) => {
        const newPath: string = path + file.name;
        uploads.push({
          state: UploadStateType.PENDING,
          path: newPath,
          file: file,
          size: file.size,
          type: file.type,
        });
      });
      dir.directories.forEach((subDir) => {
        const newPath: string = path + subDir.name + '/';
        uploads.push({
          state: UploadStateType.PENDING,
          path: newPath,
          file: null,
          size: null,
          type: 'folder',
        });
        fillUploads(subDir, newPath);
      });
    };
    fillUploads(directory, directory.name);
    return uploads;
  }

  static buildRequest(path: string, upload: Upload, bucket: string) {

    if (!path) {
      path = "";
    }

    if (upload.file) {
      return {
        Bucket: bucket,
        Key: path + upload.path,
        Body: upload.file,
      };
    } else {
      return {
        Bucket: bucket,
        Key: path + upload.path,
      };
    }
  }
}
