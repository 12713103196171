import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {FileUploadStatus, FORM_NAME, ObjectCheckInStateModel, STATE_DEFAULTS} from "./object-check-in.model";
import {
  AddFile,
  CheckInObjects,
  InitObjectCheckIn, RemoveSingleFile, RemoveAll,
  SetObjectCheckInStatus,
  ShowObjectCheckInResult, ClearCheckinForm
} from './object-check-in.actions';
import {concatMap, mergeMap, tap} from 'rxjs/operators';
import {ObjectCheckInService} from "./service/object-check-in.service";
import {
  ObjectAclStateType,
  ObjectMasterDataService
} from '@frontmania/object-master-data';
import {NotificationType, OpenNotification} from '@frontmania/notification';
import {from} from 'rxjs';
import { ResetForm } from '@ngxs/form-plugin';


@State<ObjectCheckInStateModel>({
  name: 'objectCheckIn',
  defaults: STATE_DEFAULTS
})
@Injectable({
  providedIn: 'root'
})
export class ObjectCheckInState {

  @Selector()
  static templateName(state: ObjectCheckInStateModel) {
    return state.templateName;
  }

  @Selector()
  static files(state: ObjectCheckInStateModel) {
    return state.files;
  }

  constructor(private store: Store,
              private objectMasterDataService: ObjectMasterDataService,
              private objectCheckInService: ObjectCheckInService) {
  }

  @Action(AddFile)
  private addFile(ctx: StateContext<ObjectCheckInStateModel>, action: AddFile) {

    const currentFiles = this.store.selectSnapshot(ObjectCheckInState.files);
    const newFilesArray = [...currentFiles, action.fileEntry];
    ctx.patchState({files: newFilesArray});

  }

  @Action(RemoveAll)
  private removeAllFiles(ctx: StateContext<ObjectCheckInStateModel>, action: RemoveAll) {
    if (action.successFull) {
      const currentFiles = this.store.selectSnapshot(ObjectCheckInState.files);
      const uploadErrors = currentFiles.filter(file => file.uploadStatus === FileUploadStatus.ERROR);
      ctx.patchState({files: uploadErrors});
    } else {
      ctx.patchState({files: []});
    }
  }

  @Action(RemoveSingleFile)
  private removeFile(ctx: StateContext<ObjectCheckInStateModel>, action: RemoveSingleFile) {

    const currentFiles = this.store.selectSnapshot(ObjectCheckInState.files);
    const newFilesArray = [...currentFiles];
    const index = newFilesArray.indexOf(newFilesArray.filter(entry => entry.fileName === action.fileEntry.fileName)[0]);
    if (index >= 0) {
      newFilesArray.splice(index, 1);
    }
    ctx.patchState({files: newFilesArray});
  }

  @Action(ClearCheckinForm)
  private clearCheckinForm(ctx: StateContext<ObjectCheckInStateModel>) {
    return ctx.patchState({ checkinForm: undefined});
  }

  @Action(InitObjectCheckIn)
  initObjectCheckIn(ctx: StateContext<ObjectCheckInStateModel>, action: InitObjectCheckIn) {
    return this.store.dispatch(new ResetForm({path: FORM_NAME})).pipe(
      concatMap(() => {
        return this.objectMasterDataService.loadCheckinMasterData(action.language, action.templateName).pipe(
          tap(() => ctx.patchState({templateName: action.templateName}))
        );
      })
    )

  }

  @Action(SetObjectCheckInStatus)
  setObjectCheckInStatus(ctx: StateContext<ObjectCheckInStateModel>, action: SetObjectCheckInStatus) {

    const currentFiles = this.store.selectSnapshot(ObjectCheckInState.files);
    const indexOfFileBeingUploaded = currentFiles.indexOf(currentFiles.filter(entry => entry.fileName === action.fileName)[0]);
    const newFile = {
      ...currentFiles[indexOfFileBeingUploaded], ...{
        uploadStatus: action.status,
        uploadErrorMessage: action.statusText
      }
    };
    const newFilesArray = [...currentFiles];
    newFilesArray.splice(indexOfFileBeingUploaded, 1, newFile);
    ctx.patchState({files: newFilesArray});

  }

  @Action(ShowObjectCheckInResult)
  showObjectCheckInResult(ctx: StateContext<ObjectCheckInStateModel>) {
    const currentFiles = this.store.selectSnapshot(ObjectCheckInState.files);
    const failedUploads = currentFiles.filter(entry => entry.uploadStatus === FileUploadStatus.ERROR);

    if (failedUploads.length > 0) {
      this.store.dispatch(new OpenNotification(NotificationType.ERROR, 'objectCheckIn.error'))
    } else {
      this.store.dispatch(new OpenNotification(NotificationType.SUCCESS, 'objectCheckIn.success'))
    }
    ctx.patchState({files: failedUploads});

  }

  @Action(CheckInObjects)
  checkinObjects(ctx: StateContext<ObjectCheckInStateModel>, action: CheckInObjects) {
    const templateName = this.store.selectSnapshot(ObjectCheckInState.templateName);
    const files = this.store.selectSnapshot(ObjectCheckInState.files);

    const aclWithoutType = [];
    action.acl?.filter(acl => acl.stateType !== ObjectAclStateType.DEFAULT).forEach(acl => {
      aclWithoutType.push({...acl, stateType: undefined});
    })

    return from(files.filter(file => file.uploadStatus !== FileUploadStatus.SUCCESS)).pipe(
      tap((file) => {
        this.store.dispatch(new SetObjectCheckInStatus(file.fileName, FileUploadStatus.IN_PROGRESS, file.uploadErrorMessage));
        return file;
      }),
      mergeMap(entry => this.objectCheckInService.checkInObject(aclWithoutType, action.formMetadata, entry, templateName)),
      tap(response => {
        this.store.dispatch(new SetObjectCheckInStatus(response.fileName, response.status, response.statusText))
      }),
    )
  }

}
