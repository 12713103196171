import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

/**
 * This service should be used in order to configure the component from an application itself.
 * Example: The ObjectSearch-Component is called via the routerLink, so there is only the possibility to pass route params in order to configure the component.
 * This, service allows to confiure it, without having params in the URL! Here: We may set the applyFillStratigies to true.
 * Applying them is based on roles, which are specific for each application! So logic must be there and not in the object-searach component itself!
 */
@Injectable({
  providedIn: 'root'
})
export class ObjectCheckInConfigService {
  public applyFillStrategies$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
}
