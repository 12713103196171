<fm-page>
  <fm-titlebar fmTitlebar>{{'choiceValues.listing.title' | translate}}</fm-titlebar>
  <div fmContent class="flex flex-col">

    <div class="flex flex-row">
      <mat-form-field class="background">
        <mat-label>{{'choiceValues.listing.filter' | translate}}</mat-label>
        <input matInput data-cy="cv-filter" (keyup)="applyFilter($event)"
               placeholder="{{'choiceValues.listing.filterPlaceholder' | translate}}" #filter>
      </mat-form-field>
    </div>

    <table mat-table class="fm-row-hover" [dataSource]="choiceValuesDataSource">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>
          {{'choiceValues.listing.cvByName' | translate}}

        </th>
        <td mat-cell *matCellDef="let element" [routerLink]="[element.name]"> {{element.name}} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <ng-container *ngIf="(isLoading$ | async) === false">
        <tr *matNoDataRow>
          <td colspan="3">{{'choiceValues.listing.noCvForName' | translate}} "{{filter.value}}"</td>
        </tr>
      </ng-container>
    </table>
  </div>
</fm-page>
