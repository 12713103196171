import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {UntilDestroy} from "@ngneat/until-destroy";

export interface CVDialogData {
  newValue: string;
}
@UntilDestroy()
@Component({
  selector: 'frontmania-helper-component',
  templateUrl: './new-choice-values-modal.component.html',
})

export class NewChoiceValuesModalComponent {

  constructor(
    public dialogRef: MatDialogRef<NewChoiceValuesModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CVDialogData) {
  }

  isValid(value: string) {
    return /^[a-zA-Z\d]+$/.test(value);
  }
}
