import {HttpClient} from '@angular/common/http';
import {environment} from '../environments/environment';
import {CustomHttpTranslateLoader, I18nModuleConfig} from "@frontmania/i18n";
import {AuthModuleConfig} from "@frontmania/auth";
import {NotificationConfig} from "@frontmania/notification";
import {ObjectStoreMasterDataConfig} from "@frontmania/object-master-data";
import {ObjectSearchConfig} from '@frontmania/object-search';
import {ObjectCheckInConfig} from "@frontmania/object-check-in";
import {TranslateLoader, TranslateModuleConfig} from '@ngx-translate/core';
import {S3BrowserConfig} from '@frontmania/s3-browser';
import {ChoiceValuesAdminConfig} from "@frontmania/choice-values-admin";
import {ObjectDetailsConfig} from "@frontmania/object-details";
import {DynamicFormsConfig} from "@frontmania/dynamic-forms";
import {ObjectExportConfig} from "@frontmania/object-export";
import {UserProfileConfig} from "@frontmania/user-profile";

export interface ServiceURLs {
  templateService: string,
  objectStorage: string,
  ldapSearchService: string
  i18nService: string,
  choiceValuesService: string
}

const API_PREFIX = "/api/"
const APP_IDENTIFIER = "logistics";

const URLS: { [key: string]: string } = {};
//concrete endpoints
URLS.objectSearch = API_PREFIX + "documents/search";
URLS.objectCountByQuery = API_PREFIX + "documents/countByQuery";
URLS.classDefinitions = API_PREFIX + "classDefinitions/:name";
URLS.choiceValues = API_PREFIX + "choiceValues/logistics";
URLS.objectDetails = API_PREFIX + "documents";
URLS.objectCheckIn = API_PREFIX + "documents";
URLS.searchTemplate = API_PREFIX + "template/" + APP_IDENTIFIER + "/search/:templateName";
URLS.objectDetailsTemplate = API_PREFIX + "template/" + APP_IDENTIFIER + "/edit/:templateName";
URLS.objectCheckInTemplate = API_PREFIX + "template/" + APP_IDENTIFIER + "/eci/:templateName";
URLS.securtiyTemplatesForLogistics = API_PREFIX + "template/" + APP_IDENTIFIER + "/security";
URLS.roleSearch = API_PREFIX + "ldap/roles";
URLS.translate = API_PREFIX + "translations/" + APP_IDENTIFIER + "/frontend";

function normalize(url: string) {
  return url.endsWith("/") ? url.substring(0, url.length - 1) : url;
}

export const translateModuleConfig: TranslateModuleConfig = {
  loader: {
    provide: TranslateLoader,
    useFactory: I18nTranslationLoaderFactory,
    deps: [HttpClient]
  }
};

export function objectDetailsConfigFactory(): ObjectDetailsConfig {
  const baseURLObjectStore = normalize(environment.serviceBaseUrls.objectStorage);
  return {
    objectDetailsUrl: baseURLObjectStore + URLS.objectDetails
  };
}

export function objectExportConfigFactory(): ObjectExportConfig {
  return {};
}

export function objectSearchConfigFactory(): ObjectSearchConfig {
  const baseURLObjectStore = normalize(environment.serviceBaseUrls.objectStorage);
  const baseURLTemplateService = normalize(environment.serviceBaseUrls.templateService);
  return {
    templateApiUrl: baseURLTemplateService + URLS.searchTemplate,
    dataTableColumnsUrl: baseURLTemplateService + URLS.searchTemplate,
    objectSearchUrl: baseURLObjectStore + URLS.objectSearch,
    objectCountUrl: baseURLObjectStore + URLS.objectCountByQuery,
    attachmentsUrl: baseURLObjectStore + URLS.objectDetails + "/{objectId}/attachments",
    downloadUrl: baseURLObjectStore + URLS.objectDetails + "/{objectId}/content/attachment",
    inlineContentUrl: baseURLObjectStore + URLS.objectDetails + "/{objectId}/content/inline",
    maxDocCount: 1000
  };
}

export function objectStoreMasterDataConfigFactory(): ObjectStoreMasterDataConfig {
  const baseURLObjectStore = normalize(environment.serviceBaseUrls.objectStorage);
  const baseURLTemplateService = normalize(environment.serviceBaseUrls.templateService);
  const choiceValuesURL = normalize(environment.serviceBaseUrls.choiceValuesService);
  return {
    classPropertiesUrl: baseURLObjectStore + URLS.classDefinitions,
    choiceValuesUrl: choiceValuesURL + URLS.choiceValues,
    searchTemplateUrl: baseURLTemplateService + URLS.searchTemplate,
    checkinTemplateUrl: baseURLTemplateService + URLS.objectCheckInTemplate,
    editTemplateUrl: baseURLTemplateService + URLS.objectDetailsTemplate
  };
}

function I18nTranslationLoaderFactory(http: HttpClient) {
  return new CustomHttpTranslateLoader(http, normalize(environment.serviceBaseUrls.i18nService) + URLS.translate);
}

export function notificationModuleConfigFactory(): NotificationConfig {
  return {
    fadeOutAfterMs: 10000
  }
}

export function i18nModuleConfigFactory(): I18nModuleConfig {
  return {
    supportedLocales: environment.availableLocales,
    defaultLocale: environment.defaultLocale
  }
}

export function authModuleConfigFactory(): AuthModuleConfig {
  const baseURLTemplateService = normalize(environment.serviceBaseUrls.templateService);
  return {
    ...environment.oauth2,
    securityTemplateEndpoint: baseURLTemplateService + URLS.securtiyTemplatesForLogistics,
  };
}

export function objectCheckInConfigFactory(): ObjectCheckInConfig {
  const baseURLObjectStore = normalize(environment.serviceBaseUrls.objectStorage);
  const baseURLTemplateService = normalize(environment.serviceBaseUrls.templateService);
  return {
    templateApiUrl: baseURLTemplateService + URLS.objectCheckInTemplate,
    objectCheckInUrl: baseURLObjectStore + URLS.objectCheckIn,
    masterDataOrigin: normalize(environment.masterDataOrigin)
  };
}

export function dynamicFormsConfigFactory(): DynamicFormsConfig {
  return {
    ldapUrl: normalize(environment.serviceBaseUrls?.ldapSearchService) + URLS.roleSearch,
    masterDataOriginUrl: environment.masterDataOrigin
  };
}

export function choiceValuesAdminConfigFactory(): ChoiceValuesAdminConfig {
  const baseURLChoiceValues = normalize(environment.serviceBaseUrls.choiceValuesService);
  return {
    choiceValuesUrl: baseURLChoiceValues + URLS.choiceValues,
  };
}

export function s3BrowserConfigFactory(): S3BrowserConfig {
  const baseURLObjectStore = normalize(environment.serviceBaseUrls.objectStorage)
  return {
    s3EndpointUrl: environment.s3EndpointUrl,
    s3CredentialsUrl: baseURLObjectStore + API_PREFIX,
    objectSearchUrl: baseURLObjectStore + URLS.documentSearch,
    objectCountUrl: baseURLObjectStore + URLS.objectCountByQuery,
    objectBucketCountUrl: baseURLObjectStore + URLS.objectCountByQuery
  };
}

export function userProfileConfigFactory(): UserProfileConfig {
  return {};
}
