import {Routes} from '@angular/router';
import {authGuard, SecurityTemplates} from '@frontmania/auth';
import {ListingComponent} from './components/listing/listing.component';
import {EditingComponent} from './components/editing/editing.component';




export const routes: Routes = [
  {
    path: 'choice-values-admin/:templateName',
    component: ListingComponent,
    canActivate: [authGuard],
    data: { securityTemplate: SecurityTemplates.CHOICE_VALUES_ADMIN_ACCESS }
  },
  {
    path: 'choice-values-admin/:templateName/:choiceValueName',
    component: EditingComponent,
    canActivate: [authGuard],
    data: { securityTemplate: SecurityTemplates.CHOICE_VALUES_ADMIN_ACCESS }
  },
];
