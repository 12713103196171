import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ListingComponent} from './components/listing/listing.component';
import {EditingComponent} from './components/editing/editing.component';
import {MaterialModule} from '@frontmania/material';
import {I18nModule} from '@frontmania/i18n';
import {NgxsModule} from '@ngxs/store';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {ChoiceValuesAdminConfig} from "./choice-values-admin.config";
import {NewChoiceValuesModalComponent} from "./components/helper-dialogs/new-choice-values-modal.component";
import {routes} from './choice-values-admin.routing';
import {ChoiceValuesAdminState} from "./choice-values-admin.state";

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        NgxsModule.forFeature([ChoiceValuesAdminState]),
        I18nModule,
        RouterModule.forChild(routes),
        ReactiveFormsModule,
        FormsModule,
        DragDropModule,
    ],
  declarations: [ListingComponent, EditingComponent, NewChoiceValuesModalComponent],
  exports: [ListingComponent, EditingComponent, NewChoiceValuesModalComponent],
})
export class ChoiceValuesAdminModule {

  static forRoot(config?: ChoiceValuesAdminConfig): ModuleWithProviders<ChoiceValuesAdminModule> {
    return {
      "ngModule": ChoiceValuesAdminModule,
      "providers": [{
        provide: ChoiceValuesAdminConfig,
        useValue: config
      }]
    };
  }
}
